import { FleetHealthEventDetail } from '../../fleetHealth/events/FleetHealtEventDetail';
import { FloatingCardsLayout } from '../../layout/FloatingCardsLayout';
import { FleetHealthEventLogsProvider } from '../../providers/FleetHealthEventLogsProvider';
import FleetHealthProvider from '../../providers/FleetHealtProvider';

function FleetHealthEventDetailPage() {
  return (
    <>
      <FleetHealthProvider />
      <FleetHealthEventLogsProvider />
      <FloatingCardsLayout
        contentSize={720}
        content={<FleetHealthEventDetail />}
      />
    </>
  );
}

export default FleetHealthEventDetailPage;
