import { ApiEndpoint, PaginatedResponse } from '../types/api';
import { ApiError } from '../types/apiResponseError';
import { IdName } from '../types/app';
import { postRequest } from './api';
import { URLS } from './urls/urls';

export const getDeviceModelsSearch = async (
  page?: number,
  q?: string,
  include?: string[],
  exclude?: string[]
): Promise<{ error?: ApiError; deviceModelsPaginated: PaginatedResponse<IdName> }> => {
  const body = {
    page: page ?? 1,
    q,
    include: include ?? [],
    exclude: exclude ?? [],
  };
  const response = await postRequest(
    URLS.deviceModels.search,
    {
      body,
    },
    ApiEndpoint.core
  );
  return {
    deviceModelsPaginated: response?.data,
    error: response?.error,
  };
};
