import { FlexColumn, FlexRow, LabeledData, Loading } from '@gorila-shared-ui/components';
import Papa from "papaparse";
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../hooks/useStyles';
import { MassiveCommand } from '../../../types/massiveCommands';
import { useLoading } from '../../../hooks/useLoading';
import { getMassiveCommand } from '../../../services/massiveCommandService';
import { blobToFile } from '../../../utils/files';
import { CSVPreview } from '../../shared/CSVPreview';
import { useMoment } from '../../../hooks/useMoment';
import { LabelMedium } from 'baseui/typography';
import { commandsState } from '../../../storage/commands';
import { useRecoilValue } from 'recoil';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  massiveCommandId: string;
};

function MassiveCommsDetailModal({ onClose, isOpen, massiveCommandId }: Props) {
  const { css, classes } = useStyles();
  const [massive, setMassive] = useState<MassiveCommand>();
  const commadTypes = useRecoilValue(commandsState);
  const { loading, startLoading, stopLoading } = useLoading();
  const [file, setFile] = useState<File>();
  const { formatDateFromTs } = useMoment();
  const LABEL_WIDTH = '80px';

  useEffect(() => {
    startLoading();
    const loadMassiveCommandData = async () => {
      const response = await getMassiveCommand(massiveCommandId);
      if (response?.error) {
        setMassive(undefined);
        setFile(undefined);
      } else if (response?.massiveCommand) {
        setMassive(response.massiveCommand);
        const csv = Papa.unparse({
          fields: ['idents'],
          data: response?.massiveCommand?.idents?.map((ident) => [ident]) || [],
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        setFile(blobToFile(blob, response.massiveCommand.deviceModel.name));
      }
      stopLoading();
    };
    loadMassiveCommandData();
  }, [massiveCommandId])

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      {loading && (
        <FlexColumn
          centered
          classNames={css({
            height: '400px',
            width: '400px',
          })}
        >
          <Loading />
        </FlexColumn>
      )}
      {!loading && (
        <>  
          <FlexRow
            gap={0}
            classNames={css({
              justifyContent: 'space-between',
              margin: '6px 2rem 0px 0px',
            })}
          >
            <ModalHeader
              className={css({
                marginBottom: '0 !important',
              })}
            >
              Envio masivo al modelo: {massive?.deviceModel.name}
            </ModalHeader>
          </FlexRow>
          <ModalBody>
            <FlexColumn
              classNames={css({
                width: '70vw',
                maxWidth: '1200px',
                minWidth: '350px',
                boxSizing: 'border-box',
                padding: '1rem',
                borderTop: '1px solid rgb(226, 226, 226)',
                maxHeight: '80vh',
              })}
            >
              <LabeledData 
                label="Comando"
                content={commadTypes?.find((command) => command.commandName === massive?.command)?.commandDescription || massive?.command}
                labelWidth={LABEL_WIDTH}
              />
              <LabeledData 
                label="Creado"
                content={massive?.created ? formatDateFromTs(massive.created) : '-'}
                labelWidth={LABEL_WIDTH}
              />
              <LabelMedium className={classes.dataHeaderClass}>Dispositivos</LabelMedium>
              {file && (
                <CSVPreview
                  file={file}
                  onChange={() => {}}
                  onlyRead
                />
              )}
            </FlexColumn>
          </ModalBody>
        </>
      )}
    </Modal>,
    document.body
  );
}

export default memo(MassiveCommsDetailModal);
