import { ApiEndpoint } from '../types/api';
import { ApiError } from '../types/apiResponseError';
import { CommandCore } from '../types/command';
import { MassiveCommand, MassiveCommandRequest } from '../types/massiveCommands';
import { getRequest, postRequest } from './api';
import { URLS } from './urls/urls';

export const getMassiveCommands = async (
  page?: number
): Promise<{ massiveCommands?: MassiveCommand[]; error?: ApiError; total?: number }> => {
  const params = {
    page,
  };
  const response = await getRequest(URLS.massiveCommands.all, {
    queryParams: params,
  }, ApiEndpoint.core);
  return {
    massiveCommands: response?.data?.items,
    total: response?.data?.total,
    error: response?.error,
  };
};

export const getMassiveCommand = async (massiveCommandId: string): Promise<{ massiveCommand?: MassiveCommand; error?: ApiError }> => {
  const response = await getRequest(URLS.massiveCommands.detail(massiveCommandId), {}, ApiEndpoint.core);
  return {
    massiveCommand: response?.data,
    error: response?.error,
  };
};

export const postMassiveCommand = async (massiveCommanRequest: MassiveCommandRequest): Promise<{ error?: ApiError }> => {
  const response = await postRequest(URLS.massiveCommands.create, {
    body: {
      ...massiveCommanRequest
    },
  }, ApiEndpoint.core);
  return {
    error: response?.error,
  };
};

export const getAvailableCommands = async (deviceModelId: string): Promise<{ commands?: CommandCore[]; error?: ApiError }> => {
  const response = await getRequest(URLS.commands.availableByDeviceModel(deviceModelId), {}, ApiEndpoint.core);
  return {
    commands: response?.data?.commands,
    error: response?.error,
  };
};