import { useSetRecoilState } from 'recoil';
import useEffectOnce from '../../hooks/useEffectOnce';
import { getFleetHealthAlarmTypes, getFleetHealthEventStatusList } from '../../services/fleetHealthService';
import { fleetHealthAlarmTypesState, fleetHealthEventStatusListState } from '../../storage/fleetHealthStates';

function FleetHealthProvider() {
  const setAlarmTypes = useSetRecoilState(fleetHealthAlarmTypesState);
  const setEventStatusList = useSetRecoilState(fleetHealthEventStatusListState);

  useEffectOnce(() => {
    const getAlarmTypes = async () => {
      const { fleetHealthAlarmTypes } = await getFleetHealthAlarmTypes();
      if (fleetHealthAlarmTypes) setAlarmTypes(fleetHealthAlarmTypes);
      else setAlarmTypes(undefined);
    };
    getAlarmTypes();
  });

  useEffectOnce(() => {
    const getEventStatusList = async () => {
      const { fleetHealthEventStatusList } = await getFleetHealthEventStatusList();
      if (fleetHealthEventStatusList) setEventStatusList(fleetHealthEventStatusList);
      else setEventStatusList(undefined);
    };
    getEventStatusList();
  });

  return null;
}

export default FleetHealthProvider;
