import { EllipsedText, FlexColumn, LabeledData, LabeledXSData } from '@gorila-shared-ui/components';
import { LabelMedium, LabelXSmall, ParagraphSmall, ParagraphXSmall } from 'baseui/typography';
import { ComponentProps, Fragment, memo } from 'react';
import { useStyles } from '../../hooks/useStyles';

export type SectionedDataT = Omit<ComponentProps<typeof LabeledData>, 'labelWidth'>;

type Props = {
  title: string;
  labelWidth?: string;
  data: SectionedDataT[];
  containerGap?: string | 0;
  dataGap?: string | 0;
  size?: 'default' | 'small';
};

function SectionedData({
  title,
  data,
  labelWidth = '100px',
  containerGap,
  dataGap,
  size = 'default',
}: Readonly<Props>) {
  const { theme, classes } = useStyles();
  containerGap = containerGap ?? theme.sizing.scale200;
  dataGap = dataGap ?? theme.sizing.scale200;

  return (
    <FlexColumn gap={containerGap}>
      {size === 'small' && <LabelXSmall className={classes.dataHeaderClass}>{title}</LabelXSmall>}
      {size === 'default' && <LabelMedium className={classes.dataHeaderClass}>{title}</LabelMedium>}
      <FlexColumn gap={dataGap}>
        {data.map((props, index) => (
          <Fragment key={index}>
            {size === 'small' && (
              <LabeledXSData
                {...props}
                labelWidth={labelWidth}
                content={
                  typeof props.content === 'string' ? (
                    <ParagraphXSmall margin={0}>
                      <EllipsedText inline={false}>{props.content}</EllipsedText>
                    </ParagraphXSmall>
                  ) : (
                    props.content
                  )
                }
              />
            )}
            {size === 'default' && (
              <LabeledData
                {...props}
                labelWidth={labelWidth}
                content={
                  typeof props.content === 'string' ? (
                    <ParagraphSmall margin={0}>
                      <EllipsedText inline={false}>{props.content}</EllipsedText>
                    </ParagraphSmall>
                  ) : (
                    props.content
                  )
                }
              />
            )}
          </Fragment>
        ))}
      </FlexColumn>
    </FlexColumn>
  );
}

export default memo(SectionedData);
