import { ContentContainer } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { fleetHealthRulesSearchState } from '../../../storage/fleetHealthStates';
import { FleetHealthRulesFiltersBar } from '../../fleetHealth/FleetHealthRulesFiltersBar';
import { FleetHealthRuleList } from '../../fleetHealth/FleetHealthRulesList';
import FleetHealthProvider from '../../providers/FleetHealtProvider';

export function FleetHealthRulesPage() {
  const { search, setSearch } = useDebouncedSearch(useRecoilState(fleetHealthRulesSearchState));
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <FleetHealthProvider />
      <FleetHealthRulesFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
      />
      <FleetHealthRuleList
        search={search}
        reloadPage={reloadPage}
      />
    </ContentContainer>
  );
}
