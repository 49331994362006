import {
  EllipsedText,
  EmptyState,
  FlexColumn,
  FlexRow,
  Loading,
  MaterialIcon,
  StyledTooltip,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { StyledLink } from 'baseui/link';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { HeadingXSmall, LabelMedium, LabelSmall } from 'baseui/typography';
import { Key, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { INSTALLATIONS_DETAIL_PAGE } from '../../../constants/app';
import { useCamera } from '../../../hooks/useCamera';
import { useFleetHealthEventDetail } from '../../../hooks/useFleetHealthEventDetail';
import { useModal } from '../../../hooks/useModal';
import { useStyles } from '../../../hooks/useStyles';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { isValidLatitude } from '../../../utils/map';
import { FloatingCardContent } from '../../layout/cards/FloatingCardContent';
import { AssignmentLate } from '../../ui/MaterialIcons';
import FleetHealthEventData from './FleetHealthEventData';
import { FleetHealthEventLogs } from './FleetHealthEventLogs';
import FleetHealthEventTimeline from './FleetHealthEventTimeline';
import { FleetHealthEventStatusModal } from './modal/FleetHealtEventStatusModal';
import FleetHealthEventAssetModal from './modal/FleetHealthEventAssetModal';
import { FleetHealthEventInstallationConfirmModal } from './modal/FleetHealthInstallationConfirmModal';
import { Tag } from 'baseui/tag';

export function FleetHealthEventDetail() {
  const { theme, css, classes, concatenateClasses } = useStyles();
  const { zoomTo } = useCamera();
  const { event, eventName, eventDatetime, loadingEvent, eventStatus, reloadEvent } = useFleetHealthEventDetail();
  const [initialZoom, setInitialZoom] = useState(false);
  const [activeTab, setActiveTab] = useState<Key>('timeline');
  const { closeModal, openModal, showModal } = useModal();
  const { closeModal: closeStatusModal, openModal: openStatusModal, showModal: showStatusModal } = useModal();
  const {
    closeModal: closeInstallationModal,
    openModal: openInstallationModal,
    showModal: showInstallationModal,
  } = useModal();
  const [emissionId, setEmissionId] = useState<string | undefined>();

  useUpdateEffect(() => {
    if (event?.lastEvent && !initialZoom) {
      const lat = isValidLatitude(event.lastEvent.latitude) ? event.lastEvent.latitude : undefined;
      const lng = isValidLatitude(event.lastEvent.longitude) ? event.lastEvent.longitude : undefined;
      if (lat && lng) {
        zoomTo({ lat, lng });
      }
      setInitialZoom(true);
    }
  }, [event?.lastEvent]);

  const onUpdate = () => {
    reloadEvent();
  };

  const onAttend = (emissionId?: string) => {
    setEmissionId(emissionId);
    openInstallationModal();
  };

  return (
    <FloatingCardContent
      flexible
      gap={theme.sizing.scale600}
    >
      {!event && !loadingEvent && <EmptyState title="No se pudo cargar el evento." />}
      {!event && loadingEvent && <Loading />}
      {event && (
        <>
          <FlexRow gap={theme.sizing.scale300}>
            <FlexRow classNames={concatenateClasses(classes.centeredStart, classes.flexible(1))}>
              <FlexRow
                gap={theme.sizing.scale200}
                classNames={classes.centeredStart}
              >
                <HeadingXSmall margin={0}>
                  <EllipsedText
                    breakAll
                    inline
                  >
                    {eventName}
                  </EllipsedText>
                </HeadingXSmall>
                <LabelMedium
                  margin={0}
                  color={theme.colors.contentSecondary}
                >
                  <EllipsedText
                    breakAll
                    inline
                  >
                    {`- ${eventDatetime}`}
                  </EllipsedText>
                </LabelMedium>
                <Tag
                  closeable={false} 
                  kind={event.asset.isSecondary ? 'warning' : 'positive' }
                  overrides={{
                    Root: {
                      style: () => ({
                        marginTop: 0,
                        marginBottom: 0,
                      })
                    },
                    Text: {
                      style: () => ({
                        fontSize: '12px',
                        lineHeight: '14px'
                      })
                    }
                  }}
                >
                  {event.asset.isSecondary ? 'Es secundario' : 'Es primario' }
                </Tag>
              </FlexRow>
            </FlexRow>
            <FlexRow
              gap={theme.sizing.scale100}
              classNames={css({ alignItems: 'center' })}
            >
              <StyledTooltip
                content="Información del Activo"
                showArrow={false}
                placement="bottom"
                ignoreBoundary
              >
                <Button
                  shape="circle"
                  kind="tertiary"
                  size="compact"
                  onClick={openModal}
                >
                  <MaterialIcon
                    name="info"
                    size="mini"
                  />
                </Button>
              </StyledTooltip>
              <StyledTooltip
                content="Última posición"
                showArrow={false}
              >
                <Button
                  shape="circle"
                  kind="tertiary"
                  size="compact"
                  onClick={() => zoomTo({ lat: event.lastEvent.latitude, lng: event.lastEvent.longitude })}
                >
                  <MaterialIcon
                    name="location_searching"
                    size="mini"
                  />
                </Button>
              </StyledTooltip>
              <StyledTooltip
                content="Actualizar"
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="circle"
                  onClick={onUpdate}
                  kind="tertiary"
                  isLoading={loadingEvent}
                >
                  <MaterialIcon
                    name={'sync'}
                    size="mini"
                  />
                </Button>
              </StyledTooltip>
              {event.status === 'in_progress' && (
                <StyledTooltip
                  content="Atender evento"
                  showArrow={false}
                  placement="bottom"
                  ignoreBoundary
                >
                  <Button
                    size="compact"
                    kind="tertiary"
                    shape="circle"
                    onClick={openStatusModal}
                  >
                    <AssignmentLate
                      color={theme.colors.contentPrimary}
                      size={theme.sizing.scale700}
                    />
                  </Button>
                </StyledTooltip>
              )}
            </FlexRow>
          </FlexRow>
          {event.emissionId && (
            <FlexRow classNames={classes.centeredEnd}>
              <StyledLink
                href={`${generatePath(INSTALLATIONS_DETAIL_PAGE.route, {
                  installationId: event.emissionId ?? '',
                })}`}
                target="_blank"
              >
                Ver emisión
              </StyledLink>
            </FlexRow>
          )}
          <FlexColumn gap={theme.sizing.scale300}>
            <FleetHealthEventData
              event={event}
              fullData
            />
          </FlexColumn>
          <FlexRow
            centered
            gap={theme.sizing.scale200}
            classNames={css({
              background: theme.colors.backgroundTertiary,
              padding: theme.sizing.scale200,
              borderRadius: theme.sizing.scale300,
              height: theme.sizing.scale1000,
              boxSizing: 'border-box',
            })}
          >
            <LabelSmall>
              <span style={{ color: theme.colors.contentSecondary }}>Estado:</span> {eventStatus}
            </LabelSmall>
          </FlexRow>
          <Tabs
            activeKey={activeTab}
            onChange={({ activeKey }) => {
              setActiveTab(activeKey);
            }}
            overrides={{
              Root: {
                style: {
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  overflow: 'hidden',
                },
              },
            }}
          >
            <Tab
              title="Timeline"
              key="timeline"
              overrides={{
                TabPanel: {
                  style: ({ hidden }) => {
                    return {
                      display: hidden ? 'none' : 'flex',
                      flexDirection: 'column',
                      overflow: 'hidden',
                      flex: 1,
                    };
                  },
                },
              }}
            >
              <FleetHealthEventTimeline />
            </Tab>
            <Tab
              title={'Logs'}
              key="logs"
              overrides={{
                TabPanel: {
                  style: ({ hidden }) => {
                    return {
                      display: hidden ? 'none' : 'flex',
                      flexDirection: 'column',
                      overflow: 'hidden',
                      flex: 1,
                    };
                  },
                },
              }}
            >
              <FleetHealthEventLogs />
            </Tab>
          </Tabs>
          <FleetHealthEventAssetModal
            event={event}
            onClose={closeModal}
            isOpen={showModal}
          />
          <FleetHealthEventStatusModal
            isOpen={showStatusModal}
            event={event}
            closeFn={closeStatusModal}
            onConfirmFn={onAttend}
          />
          {emissionId && (
            <FleetHealthEventInstallationConfirmModal
              isOpen={showInstallationModal}
              emissionId={emissionId}
              closeFn={closeInstallationModal}
            />
          )}
        </>
      )}
    </FloatingCardContent>
  );
}
