import { FlexColumn, LabeledInput } from '@gorila-shared-ui/components';
import { Checkbox } from 'baseui/checkbox';
import { ProgressSteps, Step } from 'baseui/progress-steps';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useForms } from '../../hooks/useForms';
import {
  editFleetHealthRuleState,
  editFleetHealthRuleTouchedState,
  editFleetHealthRuleValidState,
} from '../../storage/fleetHealthStates';
import { FleetHealthRuleRequest, FleetHealthRuleRequestTouched } from '../../types/fleetHealth';
import { DeviceModelListPicker } from '../deviceModels/DeviceModelListPicker';
import { FleetHealthAlarmTabs } from './FleetHealthAlarmTabs';

type Props = {
  currentStep: number;
};

export function FleetHealthRuleFormSteps({ currentStep }: Readonly<Props>) {
  const [form, setForm] = useRecoilState(editFleetHealthRuleState);
  const [formTouched, setFormTouched] = useRecoilState(editFleetHealthRuleTouchedState);
  const formValid = useRecoilValue(editFleetHealthRuleValidState);
  const { onTouched, onChangeInput, onValidateField } = useForms<FleetHealthRuleRequest, FleetHealthRuleRequestTouched>(
    setForm,
    setFormTouched
  );

  const generalDataStep = (
    <Step
      title="Datos Generales"
      key="generalRuleData"
    >
      <FlexColumn>
        <LabeledInput
          label="Nombre"
          placeholder="Ingrese el nombre de la regla"
          value={form.name}
          onChange={(value) => {
            onChangeInput('name', value);
          }}
          onBlur={() => onTouched('nameTouched')}
          error={onValidateField(formTouched.nameTouched, formValid.name)}
          required
        />
        <LabeledInput
          label="Tiempo max. sin comunicar"
          placeholder="Ingrese el tiempo max. sin comunicar"
          value={form.maxNoCommunication ?? undefined}
          onChange={(value) => {
            onChangeInput('maxNoCommunication', value);
          }}
          onBlur={() => onTouched('maxNoCommunicationTouched')}
          error={
            formTouched.maxNoCommunicationTouched && !formValid.maxNoCommunication && 'El valor debe ser mayor a 0.'
          }
          endEnhancer="min"
          caption="Si el valor es nulo, no se considerará para la evaluación."
          type="number"
          min={1}
        />
        <LabeledInput
          label="Batería mínima"
          placeholder="Ingrese el porcentaje de batería mínima"
          value={form.minBattery ?? undefined}
          onChange={(value) => {
            onChangeInput('minBattery', value);
          }}
          onBlur={() => onTouched('minBatteryTouched')}
          error={
            formTouched.minBatteryTouched &&
            !formValid.minBattery &&
            'El valor debe ser mayor o igual a 0 y menor o igual a 100.'
          }
          endEnhancer="%"
          caption="Si el valor es nulo, no se considerará para la evaluación."
          type="number"
          min={0}
          max={100}
        />
        <Checkbox
          checked={form.active}
          onChange={() => onChangeInput('active', !form.active)}
        >
          Activo
        </Checkbox>
      </FlexColumn>
    </Step>
  );

  const modelsStep = (
    <Step
      title="Modelos"
      key="modelsData"
    >
      <DeviceModelListPicker
        assignedDeviceModelIds={form.models}
        onAssignedChange={(ids) => onChangeInput('models', ids)}
        required
      />
    </Step>
  );

  const alarmsStep = (
    <Step
      title="Alarmas"
      key="alarmsData"
    >
      <FleetHealthAlarmTabs />
    </Step>
  );

  const steps = [generalDataStep, modelsStep, alarmsStep];

  return (
    <ProgressSteps
      current={currentStep}
      overrides={{
        Root: {
          style: {
            overflow: 'auto',
          },
        },
      }}
    >
      {steps}
    </ProgressSteps>
  );
}
