import { useEffect, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import { useFeedback } from '../../hooks/useFeedback';
import { useLoading } from '../../hooks/useLoading';
import { useStyles } from '../../hooks/useStyles';
import { createFleetHealthRule, updateFleetHealthRule } from '../../services/fleetHealthService';
import {
  editFleetHealthRuleErrorState,
  editFleetHealthRuleState,
  editFleetHealthRuleTouchedState,
  editFleetHealthRuleValidState,
} from '../../storage/fleetHealthStates';
import { FleetHealthRule } from '../../types/fleetHealth';
import { FormStepsActionsRow } from '../shared/FormStepsActionsRow';
import { FlexColumn } from '../ui/FlexColumn';
import { FleetHealthRuleFormSteps } from './FleetHealthRuleFormSteps';

type Props = {
  onCancel: () => void;
  afterSave: () => void;
  fleetHealthRule?: FleetHealthRule;
};
export function FleetHealthRuleForm({ onCancel, afterSave, fleetHealthRule }: Readonly<Props>) {
  const { css } = useStyles();
  const [form, setForm] = useRecoilState(editFleetHealthRuleState);
  const formValid = useRecoilValue(editFleetHealthRuleValidState);
  const formHasError = useRecoilValue(editFleetHealthRuleErrorState);
  const resetEditFleetHealthRuleState = useResetRecoilState(editFleetHealthRuleState);
  const resetEditFleetHealthRuleTouchedState = useResetRecoilState(editFleetHealthRuleTouchedState);
  const { loading, startLoading, stopLoading } = useLoading();
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const canSubmit = !formHasError;
  const totalSteps = 3;
  const [currentStep, setCurrentStep] = useState(0);
  const isLastStep = currentStep + 1 === totalSteps;
  const isNew = !fleetHealthRule?._id;

  useEffect(() => {
    return () => {
      clearStates();
    };
  }, []);

  useEffect(() => {
    if (fleetHealthRule) {
      const { _id, models, ...fleetHealthRuleForm } = fleetHealthRule;
      setForm((prev) => {
        return {
          ...prev,
          ...fleetHealthRuleForm,
          models: models.map((model) => model._id),
        };
      });
    }
  }, [fleetHealthRule]);

  const onSave = async () => {
    if (!canSubmit) return;
    startLoading();
    const { error } = fleetHealthRule?._id
      ? await updateFleetHealthRule(fleetHealthRule._id, form)
      : await createFleetHealthRule(form);
    if (!error) {
      afterSave();
      if (fleetHealthRule?._id)
        showPositiveFeedback(FEEDBACK.edited(FEEDBACK_PREFIXES.fleetHealthRule, fleetHealthRule.name));
      else showPositiveFeedback(FEEDBACK.created(FEEDBACK_PREFIXES.fleetHealthRule));
    } else {
      showFailFeedback(error);
    }
    stopLoading();
  };

  const clearStates = () => {
    resetEditFleetHealthRuleState();
    resetEditFleetHealthRuleTouchedState();
  };

  const canContinue = useMemo(() => {
    if (currentStep === 0) {
      return formValid.name && formValid.maxNoCommunication && formValid.minBattery;
    }
    if (currentStep === 1) {
      return formValid.models;
    }
    if (currentStep === 2) {
      return formValid.alarms;
    }
    return false;
  }, [formValid, currentStep]);

  return (
    <FlexColumn classNames={css({ overflow: 'hidden' })}>
      <FleetHealthRuleFormSteps currentStep={currentStep} />
      <FormStepsActionsRow
        onContinue={() =>
          flushSync(() => {
            setCurrentStep((prev) => prev + 1);
          })
        }
        onReturn={currentStep > 0 ? () => setCurrentStep((prev) => prev - 1) : undefined}
        onSubmit={onSave}
        onCancel={fleetHealthRule?._id ? onCancel : undefined}
        editMode={!isNew}
        loading={loading}
        canSubmit={canSubmit}
        canContinue={canContinue}
        isLastStep={isLastStep}
      />
    </FlexColumn>
  );
}
