import { del, get, post } from 'aws-amplify/api';
import { downloadData, getUrl, uploadData } from 'aws-amplify/storage';
import { API_ENDPOINT_NAMES } from '../constants/api';
import { environment } from '../environments/environment';
import { ApiEndpoint } from '../types/api';
import { ApiError } from '../types/apiResponseError';
import { getErrorMessage } from '../utils/apiErrorResponse';
import { convertObjectToJson } from '../utils/objects';

export const getRequest = async (
  path: string,
  params = {},
  endpint: ApiEndpoint = ApiEndpoint.crm
): Promise<{ data?: any; error?: ApiError }> => {
  try {
    const response = await get({
      apiName: API_ENDPOINT_NAMES[endpint],
      path,
      options: {
        ...convertObjectToJson(params),
      },
    }).response;
    try {
      return {
        data: await response.body?.json(),
      };
    } catch {
      return {
        data: undefined,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      error: getErrorMessage(error, { params, path }),
    };
  }
};

export const postRequest = async (
  path: string,
  params = {},
  endpoint: ApiEndpoint = ApiEndpoint.crm
): Promise<{ data?: any; error?: ApiError }> => {
  try {
    const response = await post({
      apiName: API_ENDPOINT_NAMES[endpoint],
      path,
      options: {
        ...convertObjectToJson(params),
      },
    }).response;
    try {
      return {
        data: await response.body?.json(),
      };
    } catch {
      return {
        data: undefined,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      error: getErrorMessage(error, { params, path }),
    };
  }
};

export const fileRequest = async (path: string) => {
  try {
    return {
      data: {
        url: (await getUrl({ path })).url.href,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      error: getErrorMessage(error),
    };
  }
};

export const uploadRequest = async (path: string, file: File, options = {}) => {
  try {
    return {
      data: {
        key: (await uploadData({ path, data: file, options }).result).path,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      error: getErrorMessage(error),
    };
  }
};

export const downloadRequest = async (path: string) => {
  try {
    return {
      data: {
        file: (await downloadData({ path }).result).body,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      error: getErrorMessage(error),
    };
  }
};

export const deleteRequest = async (path: string, params = {}): Promise<{ data?: any; error?: ApiError }> => {
  try {
    return {
      data: await del({
        apiName: environment.awsAPIEndpointName,
        path,
        options: {
          ...convertObjectToJson(params),
        },
      }).response,
    };
  } catch (error) {
    console.log(error);
    return {
      error: getErrorMessage(error, { params, path }),
    };
  }
};
