import { atom, selector, selectorFamily } from 'recoil';
import {
  FLEET_HEALTH_ALARM_DEFAULTS,
  FLEET_HEALTH_ALARM_TOUCHED_DEFAULTS,
  FLEET_HEALTH_EVENT_LOG_DEFAULTS,
  FLEET_HEALTH_RULE_DEFAULTS,
  FLEET_HEALTH_RULE_TOUCHED_DEFAULTS,
} from '../constants/fleetHealth';
import { PaginatedResponse } from '../types/api';
import { FleetHealthAlarmType, FleetHealthRuleRequest, FleetHealthRuleRequestTouched } from '../types/fleetHealth';
import {
  FleetHealthAlarmRequest,
  FleetHealthAlarmRequestTouched,
  FleetHealthEvent,
  FleetHealthEventLog,
  FleetHealthEventStatus,
  FleetHealthPreviousEvent,
} from './../types/fleetHealth';

export const fleetHealthAlarmTypesState = atom<FleetHealthAlarmType[] | undefined>({
  key: 'app.fleetHealth.alarmTypes',
  default: undefined,
});

export const fleetHealthRulesSearchState = atom<string | undefined>({
  key: 'app.fleetHealth.rules.search',
  default: undefined,
});

export const editFleetHealthRuleState = atom<FleetHealthRuleRequest>({
  key: 'fleetHealthRule.edit',
  default: FLEET_HEALTH_RULE_DEFAULTS,
});

export const editFleetHealthRuleTouchedState = atom<FleetHealthRuleRequestTouched>({
  key: 'fleetHealthRule.edit.touched',
  default: FLEET_HEALTH_RULE_TOUCHED_DEFAULTS,
});

export const editFleetHealthRuleValidState = selector<{ [key in keyof FleetHealthRuleRequest]: boolean }>({
  key: 'fleetHealthRule.edit.valid',
  get: ({ get }) => {
    const fields = get(editFleetHealthRuleState);

    // SET ALL FIELDS TO BE VALID
    const validFields = Object.fromEntries(Object.keys(fields).map((key) => [key, true])) as {
      [key in keyof FleetHealthRuleRequest]: boolean;
    };

    // VALIDATE REQUIRED FIELDS
    if (!fields.name) {
      validFields.name = false;
    }
    if (!fields.models.length) {
      validFields.models = false;
    }
    if (fields.alarms.length) {
      validFields.alarms = !fields.alarms.some((a) => {
        if (!a.code || !a.maxEvents || a.maxEvents <= 0 || (a.timeRange?.toString().length && a.timeRange <= 0)) {
          return true;
        }
        return false;
      });
    }
    if (fields.maxNoCommunication?.toString().length && fields.maxNoCommunication <= 0) {
      validFields.maxNoCommunication = false;
    }
    if (fields.minBattery?.toString().length && (fields.minBattery < 0 || fields.minBattery > 100)) {
      validFields.minBattery = false;
    }

    return validFields;
  },
});

export const editFleetHealthRuleErrorState = selector({
  key: 'fleetHealthRule.edit.error',
  get: ({ get }) => {
    const validFields = get(editFleetHealthRuleValidState);
    return Object.values(validFields).some((valid) => !valid);
  },
});

// Fleath health alarm

export const editFleetHealthAlarmState = atom<FleetHealthAlarmRequest>({
  key: 'fleetHealthAlarm.edit',
  default: FLEET_HEALTH_ALARM_DEFAULTS,
});

export const editFleetHealthAlarmTouchedState = atom<FleetHealthAlarmRequestTouched>({
  key: 'fleetHealthAlarm.edit.touched',
  default: FLEET_HEALTH_ALARM_TOUCHED_DEFAULTS,
});

export const editFleetHealthAlarmValidState = selector<{ [key in keyof FleetHealthAlarmRequest]: boolean }>({
  key: 'fleetHealthAlarm.edit.valid',
  get: ({ get }) => {
    const fields = get(editFleetHealthAlarmState);

    // SET ALL FIELDS TO BE VALID
    const validFields = Object.fromEntries(Object.keys(fields).map((key) => [key, true])) as {
      [key in keyof FleetHealthAlarmRequest]: boolean;
    };

    // VALIDATE REQUIRED FIELDS
    if (!fields.code) {
      validFields.code = false;
    }
    if (!fields.maxEvents || fields.maxEvents <= 0) {
      validFields.maxEvents = false;
    }
    if (fields.timeRange?.toString().length && fields.timeRange <= 0) {
      validFields.timeRange = false;
    }

    return validFields;
  },
});

export const editFleetHealthAlarmErrorState = selector({
  key: 'fleetHealthAlarm.edit.error',
  get: ({ get }) => {
    const validFields = get(editFleetHealthAlarmValidState);
    return Object.values(validFields).some((valid) => !valid);
  },
});

export const fleetHealthEventStatusListState = atom<FleetHealthEventStatus[] | undefined>({
  key: 'app.fleetHealth.statusList',
  default: undefined,
});

export const fleetHealthEventDetailState = atom<FleetHealthEvent | undefined>({
  key: 'app.fleetHealth.events.detail',
  default: undefined,
});

export const fleetHealthEventPreviousEvents = atom<FleetHealthPreviousEvent[] | undefined>({
  key: 'app.fleetHealth.previousEvents',
  default: undefined,
});

export const loadingFleetHealthEventLogsState = atom({
  key: 'app.fleetHealth.eventLogs.loading',
  default: false,
});

export const fleetHealthEventLogsState = atom<PaginatedResponse<FleetHealthEventLog> | undefined>({
  key: 'app.fleetHealth.eventLogs',
  default: undefined,
});

export const searchFleetHealthEventLogState = atom<string | undefined>({
  key: 'app.fleetHealth.eventLogs.search',
  default: undefined,
});

export const fleetHealthEventLogIdsState = selector<string[] | undefined>({
  key: 'app.fleetHealth.eventLogIds',
  get: ({ get }) => {
    const fleetHealthEventLogs = get(fleetHealthEventLogsState);
    if (!fleetHealthEventLogs) return;
    return fleetHealthEventLogs.items.map((_, index) => index.toString());
  },
});

export const fleetHealthEventLogState = selectorFamily<FleetHealthEventLog | undefined, string>({
  key: 'app.fleetHealth.eventLog',
  get:
    (id) =>
    ({ get }) => {
      const fleetHealthLogs = get(fleetHealthEventLogsState);
      return fleetHealthLogs?.items.find((_, index) => index.toString() === id);
    },
});

export const fleetHealthEventLogsPageState = atom({
  key: 'app.fleetHealth.event.page',
  default: 1,
});

export const updatefleetHealthEventLogsState = atom<string | undefined>({
  key: 'app.fleetHealth.event.update',
  default: undefined,
});

export const fleetHealthEventLogFormState = atom({
  key: 'app.fleetHealth.eventLogs.form',
  default: FLEET_HEALTH_EVENT_LOG_DEFAULTS,
});
