export type PaginatedResponse<T> = {
  total: number;
  hasNext: boolean;
  items: Array<T>;
};

export enum ApiEndpoint {
  core,
  crm,
  emissions,
}
