import { PaginatedResponse } from '../types/api';
import { ApiError } from '../types/apiResponseError';
import {
  FleetHealthAlarmType,
  FleetHealthEvent,
  FleetHealthEventLog,
  FleetHealthEventLogRequest,
  FleetHealthEventStatus,
  FleetHealthEventStatusRequest,
  FleetHealthPreviousEvent,
  FleetHealthRule,
  FleetHealthRuleRequest,
} from '../types/fleetHealth';
import { getRequest, postRequest } from './api';
import { URLS } from './urls/urls';

export const getFleetHealthAlarmTypes = async (): Promise<{
  fleetHealthAlarmTypes?: FleetHealthAlarmType[];
  error?: ApiError;
}> => {
  const response = await getRequest(URLS.fleetHealth.alarms.types.all);
  return {
    fleetHealthAlarmTypes: response?.data,
    error: response?.error,
  };
};

export const getFleetHealthRuleList = async (
  page?: number,
  q?: string
): Promise<{ fleetHealthRules?: FleetHealthRule[]; error?: ApiError; total?: number }> => {
  const params = {
    page,
    q,
  };
  const response = await getRequest(URLS.fleetHealth.rules.all, {
    queryParams: params,
  });
  return {
    fleetHealthRules: response?.data?.items,
    total: response?.data?.total,
    error: response?.error,
  };
};

export const createFleetHealthRule = async (
  fleetHealthRule: FleetHealthRuleRequest
): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    ...fleetHealthRule,
  };
  const response = await postRequest(URLS.fleetHealth.rules.create, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateFleetHealthRule = async (
  fleetHealthRuleId: string,
  fleetHealthRule: FleetHealthRuleRequest
): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    ...fleetHealthRule,
  };
  const response = await postRequest(URLS.fleetHealth.rules.update(fleetHealthRuleId), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const getFleetHealthEvents = async (
  page: number = 1,
  search: string = ''
  // TODO: eventType: "max_communication" | "battery_level" | "alarms"
): Promise<{ paginatedResponse?: PaginatedResponse<FleetHealthEvent>; error?: ApiError }> => {
  const response = await getRequest(URLS.fleetHealth.events.all, { queryParams: { page, q: search } });
  return {
    paginatedResponse: response?.data,
    error: response?.error,
  };
};

export const getFleetHealthEvent = async (
  eventId: string
): Promise<{ fleetHealthEvent?: FleetHealthEvent; error?: ApiError }> => {
  const response = await getRequest(URLS.fleetHealth.events.detail(eventId));
  return {
    fleetHealthEvent: response?.data,
    error: response?.error,
  };
};

export const getFleetHealthEventStatusList = async (): Promise<{
  fleetHealthEventStatusList?: FleetHealthEventStatus[];
  error?: ApiError;
}> => {
  const response = await getRequest(URLS.fleetHealth.events.status.all);
  return {
    fleetHealthEventStatusList: response?.data,
    error: response?.error,
  };
};

export const updateFleetHealthEventStatus = async (
  eventId: string,
  data: FleetHealthEventStatusRequest
): Promise<{ error?: ApiError }> => {
  const response = await postRequest(URLS.fleetHealth.events.status.update(eventId), {
    body: data,
  });
  return {
    error: response?.error,
  };
};

export const getFleetHealthPreviousEvents = async (
  eventId: string
): Promise<{
  previousEvents?: FleetHealthPreviousEvent[];
  error?: ApiError;
}> => {
  const response = await getRequest(URLS.fleetHealth.events.timeline.all(eventId));
  return {
    previousEvents: response?.data,
    error: response?.error,
  };
};

export const getFleetHealthEventLogs = async (
  eventId: string,
  page: number = 1
): Promise<{ paginatedResponse?: PaginatedResponse<FleetHealthEventLog>; error?: ApiError }> => {
  const response = await getRequest(URLS.fleetHealth.events.logs.all(eventId), { queryParams: { page } });
  return {
    paginatedResponse: response?.data,
    error: response?.error,
  };
};

export const createFleetHealthEventLog = async (
  eventId: string,
  log: FleetHealthEventLogRequest
): Promise<{ error?: ApiError }> => {
  const response = await postRequest(URLS.fleetHealth.events.logs.update(eventId), {
    body: log,
  });

  return {
    error: response?.error,
  };
};
