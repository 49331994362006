import { StyledButton } from '@gorila-shared-ui/components';
import { Card } from 'baseui/card';
import { ChevronRight } from 'baseui/icon';
import { forwardRef } from 'react';
import { useStyles } from '../../../hooks/useStyles';
import { GenericComponent } from '../../../types/components';

type Props = {
  isExpanded: boolean;
  left: string;
  width: number;
  onToggleExpand: () => void;
};

const ExpandableCard = forwardRef<HTMLDivElement, GenericComponent & Props>(function ExpandableCard(
  { isExpanded, left, onToggleExpand, width, ...props },
  ref
) {
  const { theme, css } = useStyles();

  return (
    <div
      ref={ref}
      className={css({
        left,
        width: `clamp(200px, ${width}px, max(100% - 400px))`,
        bottom: theme.sizing.scale500,
        position: 'absolute',
        top: theme.sizing.scale500,
        transition: 'left 0.3s ease-in-out',
      })}
    >
      <div
        className={css({
          position: 'relative',
          height: '100%',
        })}
      >
        <Card
          {...props}
          overrides={{
            Root: {
              style: {
                height: '100%',
                boxSizing: 'border-box',
                borderTopWidth: '1px',
                borderRightWidth: '1px',
                borderBottomWidth: '1px',
                borderLeftWidth: '1px',
                display: 'flex',
                flexDirection: 'column',
              },
            },
            Contents: {
              style: {
                flex: 1,
                overflow: 'hidden',
              },
            },
            Body: {
              style: {
                height: '100%',
                marginBottom: 0,
              },
            },
          }}
        />
        <StyledButton
          size="mini"
          shape="square"
          kind="primary"
          onClick={onToggleExpand}
          overrides={{
            BaseButton: {
              style: {
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translate(100%, -50%)',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                height: '40px',
                width: '18px',
              },
            },
          }}
        >
          <ChevronRight
            title=""
            size={theme.sizing.scale650}
            overrides={{
              Svg: {
                style: {
                  transition: 'transform 0.2s ease-in-out',
                  transform: isExpanded ? 'rotate(180deg)' : undefined,
                },
              },
            }}
          />
        </StyledButton>
      </div>
    </div>
  );
});

export default ExpandableCard;
