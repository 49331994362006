import { EmptyState, FlexColumn, FlexRow, Loading, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { LabelLarge } from 'baseui/typography';
import { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useStyles } from '../../../hooks/useStyles';
import {
  fleetHealthEventLogIdsState,
  fleetHealthEventLogsPageState,
  fleetHealthEventLogsState,
  loadingFleetHealthEventLogsState,
} from '../../../storage/fleetHealthStates';
import { FleetHealthEventLogForm } from './FleetHealthEventLogForm';
import FleetHealthEventLogItem from './FleetHealthEventLogItem';

export function FleetHealthEventLogs() {
  const { theme, css, classes, concatenateClasses } = useStyles();
  const eventLogIds = useRecoilValue(fleetHealthEventLogIdsState);
  const paginatedEventLogs = useRecoilValue(fleetHealthEventLogsState);
  const loadingEventLogs = useRecoilValue(loadingFleetHealthEventLogsState);
  const [eventLogsPage, setEventLogsPage] = useRecoilState(fleetHealthEventLogsPageState);
  const scrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onUpdate();
  }, []);

  const onUpdate = () => {
    setEventLogsPage(0);
  };

  return (
    <FlexColumn
      gap={0}
      classNames={concatenateClasses(classes.flexible(1), css({ overflow: 'hidden' }))}
    >
      {!eventLogIds && !loadingEventLogs && <EmptyState title="No se pudieron cargar los logs." />}
      {!eventLogIds && loadingEventLogs && <Loading />}
      {eventLogIds && (
        <>
          <FlexColumn
            gap={theme.sizing.scale200}
            classNames={concatenateClasses(classes.flexible(1), css({ overflow: 'hidden' }))}
          >
            <FlexRow
              gap={theme.sizing.scale300}
              classNames={css({ alignItems: 'center', justifyContent: 'space-between' })}
            >
              <LabelLarge>
                {eventLogIds.length} {eventLogIds.length === 1 ? 'Log' : 'Logs'}
              </LabelLarge>
              <StyledTooltip
                content={'Actualizar'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="circle"
                  onClick={onUpdate}
                  kind="tertiary"
                  isLoading={loadingEventLogs}
                >
                  <MaterialIcon
                    name={'sync'}
                    size="mini"
                  />
                </Button>
              </StyledTooltip>
            </FlexRow>
            {!!paginatedEventLogs?.items.length && (
              <div
                id="scrollableDiv"
                className={css({ flex: 1, overflow: 'auto' })}
                ref={scrollableRef}
              >
                <InfiniteScroll
                  dataLength={paginatedEventLogs?.items.length}
                  next={() => setEventLogsPage(eventLogsPage + 1)}
                  hasMore={paginatedEventLogs?.hasNext ?? false}
                  loader={<Loading />}
                  endMessage={
                    paginatedEventLogs?.hasNext && (
                      <p style={{ textAlign: 'center' }}>
                        <b>Cargando...</b>
                      </p>
                    )
                  }
                  scrollableTarget="scrollableDiv"
                  style={{
                    overflow: 'hidden',
                  }}
                >
                  <FlexColumn
                    gap={0}
                    classNames={css({ overflow: 'hidden' })}
                  >
                    {eventLogIds.map((eventLogId, index) => (
                      <FleetHealthEventLogItem
                        eventLogId={eventLogId}
                        key={eventLogId}
                        index={index}
                      />
                    ))}
                  </FlexColumn>
                </InfiniteScroll>
              </div>
            )}
          </FlexColumn>
          <FleetHealthEventLogForm />
        </>
      )}
    </FlexColumn>
  );
}
