import { MaterialIcon, StyledButton, StyledSearchBar, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useModal } from '../../hooks/useModal';
import { useStyles } from '../../hooks/useStyles';
import { FlexRow } from '../ui/FlexRow';
import FleetHealthRuleModal from './modal/FleetHealthRuleModal';

type Props = {
  search?: string;
  onChangeSearch: (search?: string) => void;
  onReloadPage: () => void;
};
export function FleetHealthRulesFiltersBar({ search, onChangeSearch, onReloadPage }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const { openModal, closeModal, showModal } = useModal();

  return (
    <FlexRow
      classNames={css({
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={css({
          width: '50%',
        })}
      >
        <StyledSearchBar
          value={search ?? ''}
          onChange={onChangeSearch}
          clearable
        />
      </FlexRow>
      <FlexRow gap={theme.sizing.scale300}>
        <StyledTooltip
          content={'Recargar tabla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onReloadPage}
            kind="tertiary"
          >
            <MaterialIcon name={'refresh'} />
          </Button>
        </StyledTooltip>
        <StyledButton
          onClick={openModal}
          startEnhancer={
            <MaterialIcon
              name="add"
              size="xs-mini"
              color={theme.colors.contentInversePrimary}
            />
          }
        >
          Nueva
        </StyledButton>
      </FlexRow>
      {showModal && (
        <FleetHealthRuleModal
          onClose={(updateTable) => {
            if (updateTable) {
              onReloadPage();
            }
            closeModal();
          }}
          isOpen={showModal}
        />
      )}
    </FlexRow>
  );
}
