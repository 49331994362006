import FleetHealthEventList from '../../fleetHealth/events/FleetHealthEventList';
import { FloatingCardsLayout } from '../../layout/FloatingCardsLayout';
import { FleetHealthEventsProvider } from '../../providers/FleetHealthEventsProvider';

function FleetHealthEventsPage() {
  return (
    <>
      <FleetHealthEventsProvider />
      <FloatingCardsLayout
        contentSize={720}
        content={<FleetHealthEventList />}
      />
    </>
  );
}

export default FleetHealthEventsPage;
