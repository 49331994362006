import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useWindowSize } from 'usehooks-ts';
import { EXPANDED_SIDENAV_WIDTH, LIST_CARD_WIDTH, SIDENAV_WIDTH } from '../../constants/app';
import { useElementWidth } from '../../hooks/useElementWidth';
import { useStyles } from '../../hooks/useStyles';
import { sidenavIsPinnedState } from '../../storage/app';
import { mapPaddingState } from '../../storage/map';
import ExpandableCard from './cards/ExpandableCard';

type Props = {
  list?: React.ReactNode;
  content?: React.ReactNode;
  contentSize?: 'small' | 'medium' | 'large' | 'auto' | number;
  listSize?: number;
};

export function FloatingCardsLayout({ list, content, contentSize = 'medium', listSize = LIST_CARD_WIDTH }: Props) {
  const { theme } = useStyles();
  const { width: windowWidth } = useWindowSize();
  const [listIsExpanded, setListIsExpanded] = useState(true);
  const [contentIsExpanded, setContentIsExpanded] = useState(true);
  const { elementRef: listRef, elementWidth: listWidth } = useElementWidth();
  const { refCallback: contentRef, elementWidth: contentWidth } = useElementWidth();
  const sidenavIsPinned = useRecoilValue(sidenavIsPinnedState);
  const setMapPadding = useSetRecoilState(mapPaddingState);

  useEffect(() => {
    if ((!list && !content) || (!listIsExpanded && !contentIsExpanded)) {
      setMapPadding(0);
      return;
    }
    if (list && content) {
      if (listIsExpanded && contentIsExpanded) {
        setMapPadding(listWidth + contentWidth + 24);
      } else if (contentIsExpanded) {
        setMapPadding(contentWidth + 12);
      }
    } else if (list && listIsExpanded) {
      setMapPadding(listWidth + 12);
    } else if (content && contentIsExpanded) {
      setMapPadding(contentWidth + 12);
    } else {
      setMapPadding(0);
    }
  }, [content, contentIsExpanded, contentWidth, list, listIsExpanded, listWidth]);

  const calculatedContentWidth = (() => {
    if (!windowWidth) return 0;
    let width = contentWidth;
    const windowSection = (windowWidth - listWidth - (sidenavIsPinned ? EXPANDED_SIDENAV_WIDTH : SIDENAV_WIDTH)) / 4;
    if (contentSize === 'small') {
      width = windowSection;
    } else if (contentSize === 'medium') {
      width = windowSection * 1.7;
    } else if (contentSize === 'large') {
      width = windowSection * 3;
    } else if (typeof contentSize === 'number') {
      width = contentSize + 36;
    }
    return width;
  })();

  const contentPosition = (() => {
    if (list && listIsExpanded) {
      return `${listWidth + 24}px`;
    }
    if (list && !listIsExpanded && contentIsExpanded) {
      return theme.sizing.scale500;
    }
    if (list && !listIsExpanded && !contentIsExpanded) {
      return `-${contentWidth}px`;
    }
    if (!list && contentIsExpanded) {
      return theme.sizing.scale500;
    }
    if (!list && !contentIsExpanded) {
      return `-${contentWidth}px`;
    }
    return '';
  })();

  const listPosition = (() => {
    if (!listIsExpanded && !contentIsExpanded) {
      return `-${listWidth + 18}px`;
    }
    if (!listIsExpanded && contentIsExpanded) {
      return `-${listWidth}px`;
    }
    if (listIsExpanded) {
      return theme.sizing.scale500;
    }
    return '';
  })();

  return (
    <>
      {content && (
        <ExpandableCard
          left={contentPosition}
          width={calculatedContentWidth}
          isExpanded={contentIsExpanded}
          onToggleExpand={() => {
            setListIsExpanded(contentIsExpanded ? false : true);
            setContentIsExpanded((prev) => !prev);
          }}
          ref={contentRef}
        >
          {calculatedContentWidth && content}
        </ExpandableCard>
      )}
      {list && (
        <ExpandableCard
          left={listPosition}
          width={listSize}
          isExpanded={listIsExpanded}
          onToggleExpand={() => setListIsExpanded((prev) => !prev)}
          ref={listRef}
        >
          {list}
        </ExpandableCard>
      )}
    </>
  );
}
