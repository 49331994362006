import { EllipsedText, FlexColumn } from '@gorila-shared-ui/components';
import { Cell, Grid } from 'baseui/layout-grid';
import { ParagraphSmall } from 'baseui/typography';
import { useMemo } from 'react';
import { ASSET_TYPE, ASSET_TYPE_VEHICLE } from '../../../constants/asset';
import { useFleetHealthEvent } from '../../../hooks/useFleetHealthEvent';
import { useMoment } from '../../../hooks/useMoment';
import { useStyles } from '../../../hooks/useStyles';
import { FleetHealtEventType, FleetHealthEvent } from '../../../types/fleetHealth';
import SectionedData, { SectionedDataT } from '../../shared/SectionedData';

type Props = {
  event: FleetHealthEvent;
  fullData?: boolean;
  size?: 'default' | 'small';
};

function FleetHealthEventData({ event, size = 'default', fullData = false }: Readonly<Props>) {
  const { theme } = useStyles();
  const { formatDateFromTs } = useMoment();
  const { getEventStatusName } = useFleetHealthEvent();
  const labelWidth = '110px';
  const { alarmConfig, deviceModel, lastEvent, status, eventType, asset } = event;

  const alarmConfigData = useMemo(() => {
    if (eventType === FleetHealtEventType.maxCommunication) {
      return [
        {
          label: 'Delta tiempo:',
          content: alarmConfig.deltaMinutes ? alarmConfig.deltaMinutes.toString() + ' min' : '-',
        },
      ];
    }
    if (eventType === FleetHealtEventType.batteryLevel) {
      return [
        {
          label: 'Batería actual:',
          content: alarmConfig.currentBattery ? alarmConfig.currentBattery.toString() + '%' : '-',
        },
      ];
    }
    return [];
  }, [alarmConfig, eventType]);

  const generalData = useMemo(() => {
    const data = [
      {
        label: 'Modelo:',
        content: deviceModel.name || '-',
      },
      {
        label: 'Ident:',
        content: asset.device.ident || '-',
      },
    ];
    if (fullData) {
      data.push({
        label: 'Tipo:',
        content: (ASSET_TYPE as { [key: string]: string })[asset.assetType as string] ?? '-',
      });
    }
    data.push(
      ...[
        {
          label: 'Cliente:',
          content: asset.client.name || '-',
        },
        {
          label: 'Subcliente:',
          content: asset.subClient.name || '-',
        },
        {
          label: asset.vehicle?.vin ? 'Vin:' : 'IdAsset:',
          content: asset.vehicle?.vin ?? (asset._id || '-'),
        },
      ]
    );
    if (!fullData) {
      return [
        {
          label: 'Estado:',
          content: getEventStatusName(status),
        },
        ...data,
        {
          label: 'Vencimiento de la Poliza:',
          content: asset.insuranceEndDate ? formatDateFromTs(asset.insuranceEndDate) : '-',
        }
      ];
    }
    if (fullData && asset.assetType === ASSET_TYPE_VEHICLE) {
      data.push({
        label: 'Placa:',
        content: asset.vehicle?.licensePlate ?? '-',
      });
    }
    return [
      ...data,
      {
        label: 'Vencimiento de la Poliza:',
        content: asset.insuranceEndDate ? formatDateFromTs(asset.insuranceEndDate) : '-',
      }
    ];
  }, [deviceModel, fullData, status, asset]);

  const lastEventData = useMemo(() => {
    const data: SectionedDataT[] = [
      {
        label: 'Fecha y hora:',
        content: formatDateFromTs(lastEvent.timestamp) || '-',
      },
      {
        label: 'Tipo de reporte:',
        content: lastEvent.notificationType.toString() || '-', // TODO: Map it
      },
      {
        label: 'Lat / Long :',
        content:
          lastEvent.latitude && lastEvent.longitude ? `${lastEvent.latitude}, ${lastEvent.longitude}`.toString() : '-',
      },
      {
        label: 'Velocidad:',
        content: lastEvent.speed + ' km/h',
      },
    ];
    if (fullData) {
      data.push(
        ...[
          {
            label: 'Batería:',
            content: lastEvent.batteryPercentage + '%' || '-',
          },
          {
            label: 'Georeferencia:',
            content: lastEvent.geoReference ? (
              <ParagraphSmall margin={0}>
                <EllipsedText maxLines={5}>{lastEvent.geoReference}</EllipsedText>
              </ParagraphSmall>
            ) : (
              '-'
            ),
          },
        ]
      );
    }
    return data;
  }, [fullData, lastEvent]);

  return (
    <Grid
      gridColumns={12}
      gridGaps={[8]}
      gridGutters={[16]}
      gridMargins={[0]}
      behavior="fluid"
      overrides={{
        GridWrapper: {
          style: {
            margin: 0,
          },
        },
      }}
    >
      <Cell span={[12, 12, 12, 6, 6]}>
        <FlexColumn>
          <SectionedData
            title="Información general"
            labelWidth={labelWidth}
            data={generalData}
            size={size}
            containerGap={0}
            dataGap={0}
          />
        </FlexColumn>
      </Cell>
      <Cell span={[12, 12, 12, 6, 6]}>
        <FlexColumn gap={theme.sizing.scale200}>
          {eventType !== FleetHealtEventType.alarms && (
            <SectionedData
              title="Motivo de alarma"
              labelWidth={labelWidth}
              data={alarmConfigData}
              size={size}
              containerGap={0}
              dataGap={0}
            />
          )}
          <SectionedData
            title="Último evento"
            labelWidth={labelWidth}
            data={lastEventData}
            size={size}
            containerGap={0}
            dataGap={0}
          />
        </FlexColumn>
      </Cell>
    </Grid>
  );
}

export default FleetHealthEventData;
