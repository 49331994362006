import { useRecoilValue } from 'recoil';
import { installationsCampaignsState } from '../storage/Installations';

export function useCampaigns() {
  const campaigns = useRecoilValue(installationsCampaignsState);

  const getCampaignByName = (name: string) => {
    return campaigns?.find((campaign) => campaign.name.toLowerCase() === name.toLowerCase())?.pk ?? '';
  };

  return {
    getCampaignByName,
  };
}
