import { FLEET_HEALTH_EVENT_LOG_TYPE_COMMENT, FLEET_HEALTH_EVENT_LOG_TYPE_STATUS } from '../constants/fleetHealth';
import { ASSET_TYPE_PHONE } from './../constants/asset';
import { IdName } from './app';
import { AssetType } from './asset';
import { VehicleType } from './vehicle';

export interface FleetHealthAlarmType extends IdName {
  notificationId: number;
  isEvent: boolean;
}

export interface FleetHealthRule extends IdName {
  models: FleetHealthModel[];
  active: boolean;
  maxNoCommunication: number | null;
  minBattery: number | null;
  alarms: FleetHealthAlarm[];
}

export interface FleetHealthAlarm {
  name: string;
  code: number;
  maxEvents: number;
  timeRange?: number;
}

export interface FleetHealthAlarmRequest extends Omit<FleetHealthAlarm, 'name' | 'code'> {
  code?: number;
}

export interface FleetHealthAlarmRequestTouched {
  codeTouched: boolean;
  maxEventsTouched: boolean;
  timeRangeTouched: boolean;
}

export interface FleetHealthModel extends IdName {}

export interface FleetHealthRuleRequest extends Omit<FleetHealthRule, '_id' | 'models'> {
  models: string[];
}

export interface FleetHealthRuleRequestTouched {
  nameTouched: boolean;
  modelsTouched: boolean;
  activeTouched: boolean;
  maxNoCommunicationTouched: boolean;
  minBatteryTouched: boolean;
  alarmsTouched: boolean;
}

export interface FleetHealthEvent {
  eventType: FleetHealtEventType;
  created: number;
  configuration: IdName;
  alarmConfig: FleethHealthEventAlarmConfig;
  status: string;
  _id: string;
  deviceModel: IdName;
  lastEvent: FleetHealthLastEvent;
  asset: FleetHealthEventAsset;
  emissionId?: string;

}

export interface HealthNoCommunicationEvent {
  maxNoCommunication?: number;
  deltaMinutes?: number;
}

export interface HealthBatteryEvent {
  minBattery?: number;
  currentBattery?: number;
}

export interface HealthAlarmEvent {
  code?: number;
  maxEvents?: number;
  timeRange?: number;
  totalEvents?: number;
  name?: string;
}

export interface FleethHealthEventAlarmConfig
  extends HealthNoCommunicationEvent,
    HealthBatteryEvent,
    HealthAlarmEvent {}

export interface FleetHealthEventSubclient extends IdName {
  rfc: string;
  tradeName: string;
  contact?: {
    fullName: string;
    email: string;
    phone: string;
  };
}

export interface FleetHealthEventAsset {
  _id: string;
  client: IdName;
  subClient: FleetHealthEventSubclient;
  device: FleetHealthEventDevice;
  vehicle?: FleetHealthEventVehicle;
  container?: FleetHealthEventContainer;
  assetType: Omit<AssetType, typeof ASSET_TYPE_PHONE>;
  label: string;
  isSecondary?: boolean;
  insuranceEndDate?: number;
}

export interface FleetHealthEventContainer {
  identifier: string;
  containerType: string;
}

export interface FleetHealthEventDevice {
  _id: string;
  ident: string;
}

export interface FleetHealthEventVehicle {
  color: string;
  year?: number;
  brand?: IdName;
  subBrand?: IdName;
  licensePlate: string;
  vehicleType: VehicleType;
  vin: string;
}

export interface FleetHealthLastEvent {
  direction: number;
  latitude: number;
  longitude: number;
  speed: number;
  timestamp: number;
  geoReference: string;
  notificationType: number;
  batteryPercentage: number;
}

export interface FleetHealthEventStatus {
  slug: string;
  value: string;
}

export interface FleetHealthEventStatusRequest {
  status: string;
  cooldownMinutes?: number | null;
  emissionId?: string;
}

export enum FleetHealtEventType {
  maxCommunication = 'max_communication',
  batteryLevel = 'battery_level',
  alarms = 'alarms',
}

export type FleetHealthEventLogType =
  | typeof FLEET_HEALTH_EVENT_LOG_TYPE_COMMENT
  | typeof FLEET_HEALTH_EVENT_LOG_TYPE_STATUS;

export interface FleetHealthEventLog {
  message: string;
  logType: FleetHealthEventLogType;
  created: number;
  createdBy: string;
}

export interface FleetHealthEventLogRequest {
  message: string;
  logType: FleetHealthEventLogType;
}

export interface FleetHealthPreviousEvent
  extends Omit<FleetHealthEvent, '_id' | 'deviceModel' | 'lastEvent' | 'asset'> {
  attendedUntil: number;
  events: FleetHealthLastEvent[];
}

export enum CooldownTime {
  '48hours' = 2880,
  '7days' = 10080,
  '15days' = 21600,
  '30days' = 43200,
  'blacklist' = 525600,
}
