import { LabeledInput } from '@gorila-shared-ui/components';
import { useSetRecoilState } from 'recoil';
import { useStyles } from '../../hooks/useStyles';
import { editFleetHealthRuleState } from '../../storage/fleetHealthStates';
import { FleetHealthAlarm } from '../../types/fleetHealth';
import { FleetHealthAlarmTypeSelect } from '../shared/selects/FleetHealthAlarmTypeSelect';
import { FlexColumn } from '../ui/FlexColumn';

type Props = {
  fleetHealthAlarm: FleetHealthAlarm;
  index: number;
};

export function FleetHealthAlarmEditForm({ fleetHealthAlarm, index }: Readonly<Props>) {
  const { css } = useStyles();
  const setForm = useSetRecoilState(editFleetHealthRuleState);

  const onChangeInput = (key: keyof FleetHealthAlarm, value: any) => {
    setForm((prev) => {
      const newAlarms = [...prev.alarms];
      const newAlarm = { ...newAlarms[index], [key]: value };
      newAlarms[index] = newAlarm;
      return {
        ...prev,
        alarms: newAlarms,
      };
    });
  };

  return (
    <FlexColumn classNames={css({ overflow: 'hidden' })}>
      <FleetHealthAlarmTypeSelect
        selectedAlarmTypeId={fleetHealthAlarm.code}
        onChangeAlarmTypeId={(id, alarmType) => {
          onChangeInput('code', id);
          if (alarmType) {
            onChangeInput('name', alarmType.name);
          }
        }}
        required
      />
      <LabeledInput
        label="No. máximo de eventos"
        placeholder="Ingrese el no. máximo de eventos"
        value={fleetHealthAlarm.maxEvents}
        onChange={(value) => {
          onChangeInput('maxEvents', value);
        }}
        type="number"
        min={0}
        error={(!fleetHealthAlarm.maxEvents || fleetHealthAlarm.maxEvents <= 0) && 'El valor debe ser mayor a 0.'}
        required
      />
      <LabeledInput
        label="Rango de tiempo"
        placeholder="Ingrese el rango de tiempo"
        value={fleetHealthAlarm.timeRange}
        onChange={(value) => {
          onChangeInput('timeRange', value);
        }}
        type="number"
        endEnhancer="min"
        min={0}
        caption="Período de evaluación en minutos. El valor predeterminado es 1440 (24 horas)"
        error={
          fleetHealthAlarm.timeRange?.toString().length &&
          fleetHealthAlarm.timeRange <= 0 &&
          'El valor debe ser mayor a 0.'
        }
      />
    </FlexColumn>
  );
}
