import {
  EmptyState,
  FlexColumn,
  FlexRow,
  Loading,
  MaterialIcon,
  StyledSearchBar,
  StyledTooltip,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { LabelMedium } from 'baseui/typography';
import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useCamera } from '../../../hooks/useCamera';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { useStyles } from '../../../hooks/useStyles';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import {
  fleetHealtEventIdsState,
  fleetHealtEventsPageState,
  fleetHealthEventsState,
  loadingFleetHealthEventsState,
  searchFleetHealtEventState,
} from '../../../storage/fleetHealthEventsStates';
import { getValidPositions } from '../../../utils/fleetHealth';
import { FloatingCardContent } from '../../layout/cards/FloatingCardContent';
import FleetHealthEventCard from './FleetHealthEventCard';
import { useDebounceFunction } from '../../../hooks/useDebounce';

function FleetHealthEventList() {
  const { theme, classes, css } = useStyles();
  const eventIds = useRecoilValue(fleetHealtEventIdsState);
  const loadingEvents = useRecoilValue(loadingFleetHealthEventsState);
  const { search, setSearch } = useDebouncedSearch(useRecoilState(searchFleetHealtEventState));
  const paginatedEvents = useRecoilValue(fleetHealthEventsState);
  const [eventsPage, setEventsPage] = useRecoilState(fleetHealtEventsPageState);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [initialZoom, setInitialZoom] = useState(false);
  const { fitToLngLats } = useCamera();
  const total = paginatedEvents?.total ?? 0;
  const current = paginatedEvents?.items.length ?? 0;

  useUpdateEffect(() => {
    if (paginatedEvents && !initialZoom) {
      fitToLngLats(getValidPositions(paginatedEvents.items));
      setInitialZoom(true);
    }
  }, [paginatedEvents]);

  const onUpdate = () => {
    setEventsPage(0);
  };

  useEffect(() => {
    if (!scrollableRef.current) return;
    if (eventsPage === 0) {
      const scrollableDiv = scrollableRef.current;
      scrollableDiv.scrollTo(0, 0);
    }
  }, [eventsPage, scrollableRef]);

  const fetchMoreData = useDebounceFunction(function () {
    setEventsPage(eventsPage + 1)
  }, 500);

  return (
    <FloatingCardContent
      flexible
      gap={theme.sizing.scale500}
    >
      {!eventIds && !loadingEvents && <EmptyState title="No cuentas con ningún evento." />}
      {!eventIds && loadingEvents && <Loading />}
      {eventIds && (
        <>
          <FlexRow gap={theme.sizing.scale300}>
            <div className={classes.flexible(1)}>
              <StyledSearchBar
                name="event-search"
                value={search}
                onChange={setSearch}
                clearable
              />
            </div>
            <StyledTooltip
              content={'Actualizar'}
              showArrow={false}
            >
              <Button
                size="compact"
                shape="square"
                onClick={onUpdate}
                kind="tertiary"
              >
                <MaterialIcon name={'sync'} />
              </Button>
            </StyledTooltip>
          </FlexRow>
          {!!paginatedEvents?.items.length && (
            <div
              id="scrollableDiv"
              className={css({ height: '100%', overflow: 'auto' })}
              ref={scrollableRef}
            >
              <InfiniteScroll
                dataLength={paginatedEvents?.items.length}
                next={fetchMoreData}
                hasMore={paginatedEvents?.hasNext ?? false}
                loader={<Loading />}
                endMessage={
                  paginatedEvents?.hasNext && (
                    <p style={{ textAlign: 'center' }}>
                      <b>Cargando...</b>
                    </p>
                  )
                }
                scrollableTarget="scrollableDiv"
                style={{
                  overflow: 'hidden',
                }}
              >
                <FlexColumn gap={'8px'}>
                  {eventIds.map((eventId) => (
                    <FleetHealthEventCard
                      id={eventId}
                      key={eventId}
                    />
                  ))}
                </FlexColumn>
              </InfiniteScroll>
            </div>
          )}
          {!eventIds.length && (
            <EmptyState
              title="No se encontraron resultados"
              description="Intenta con otra busqueda"
            />
          )}
          <FlexRow
            classNames={css({
              background: theme.colors.backgroundTertiary,
              paddingTop: theme.sizing.scale300,
              paddingBottom: theme.sizing.scale300,
              paddingRight: theme.sizing.scale600,
              paddingLeft: theme.sizing.scale600,
              borderRadius: theme.sizing.scale300,
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            {!!total && (
              <LabelMedium>
                {current} de {total} {total === 1 ? 'evento' : 'eventos'}
              </LabelMedium>
            )}
          </FlexRow>
        </>
      )}
    </FloatingCardContent>
  );
}

export default FleetHealthEventList;
