import { FlexColumn, FullContainer } from '@gorila-shared-ui/components';
import { SnackbarProvider } from 'baseui/snackbar';
import { ToasterContainer } from 'baseui/toast';
import { MapProvider } from 'react-map-gl';
import { useRecoilValue } from 'recoil';
import {
  EXPANDED_SIDENAV_WIDTH,
  FLEET_HEALTH_EVENT_DETAIL_PAGE,
  FLEET_HEALTH_EVENTS_PAGE,
  SIDENAV_WIDTH,
} from '../../constants/app';
import { useStyles } from '../../hooks/useStyles';
import { isLoggedInState, pageState, sidenavIsPinnedState } from '../../storage/app';
import { AppProvider } from '../providers/AppProvider';
import AssetsTypesProvider from '../providers/AssetsTypesProvider';
import { AuthProvider } from '../providers/AuthProvider';
import AvailableCommandsProvider from '../providers/AvailableCommandsProvider';
import CampaignProvider from '../providers/CampaignsProvider';
import InsatallationStatusProvider from '../providers/InstallationStatusProvider';
import LogsTypesProvider from '../providers/LogsTypesProvider';
import PusherProvider from '../providers/PusherProvider';
import { HeadTitle } from '../shared/HeadTitle';
import { Navbar } from '../shared/navbar/Navbar';
import { Sidenav } from '../shared/navbar/Sidenav';
import PageLayout from './PageLayout';

export function CrmLayout() {
  const { css, theme } = useStyles();
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const sidenavIsPinned = useRecoilValue(sidenavIsPinnedState);
  const page = useRecoilValue(pageState);
  const withSidenav = page !== FLEET_HEALTH_EVENT_DETAIL_PAGE.id;

  const marginLeft = (() => {
    if (!withSidenav) return;
    if (sidenavIsPinned) {
      return `${EXPANDED_SIDENAV_WIDTH}px`;
    }
    return `${SIDENAV_WIDTH}px`;
  })();

  return (
    <MapProvider>
      <SnackbarProvider placement="top">
        <AuthProvider />
        <HeadTitle />
        {isLoggedIn && (
          <>
            <AppProvider />
            <AssetsTypesProvider />
            <AvailableCommandsProvider />
            <LogsTypesProvider />
            <InsatallationStatusProvider />
            <CampaignProvider />
            {page === FLEET_HEALTH_EVENTS_PAGE.id && <PusherProvider />}
            <FullContainer flexible>
              {withSidenav && <Sidenav />}
              <FlexColumn
                classNames={css({
                  flex: 1,
                  overflowX: 'hidden',
                  marginLeft: marginLeft,
                  transition: 'margin-left 0.3s ease-in-out',
                })}
                gap={0}
              >
                <Navbar />
                <PageLayout />
              </FlexColumn>
            </FullContainer>
            <ToasterContainer
              autoHideDuration={2500}
              placement="topRight"
              resetAutoHideTimerOnUpdate
              usePortal={false}
              closeable={true}
              overrides={{
                Root: {
                  style: {
                    marginTop: '64px',
                    marginRight: theme.sizing.scale400,
                  },
                },
              }}
            />
          </>
        )}
      </SnackbarProvider>
    </MapProvider>
  );
}
