import { ViewState } from 'react-map-gl';
import { atom, selector } from 'recoil';
import { MAP_CONFIGURATION, ROAD_MAP_TYPE } from '../constants/map';
import { MapType } from '../types/app';
import { isValidLatitude, isValidLongitude } from '../utils/map';
import { localStorageEffect, persistedAtom } from '../utils/persistence';
import { fleetHealthEventsState } from './fleetHealthEventsStates';
import { fleetHealthEventPreviousEvents } from './fleetHealthStates';

export const mapTypeState = persistedAtom<MapType>('app.map.type', ROAD_MAP_TYPE);
export const mapStyleState = selector({
  key: 'app.map.styles',
  get: ({ get }) => MAP_CONFIGURATION[get(mapTypeState)].style,
});
export const mapPaddingState = atom({
  key: 'app.map.padding',
  default: 0,
});

export const mapViewState = atom<ViewState>({
  key: 'map.view',
  default: {
    longitude: -100.38806,
    latitude: 20.58806,
    zoom: 14,
  } as ViewState,
  effects: [localStorageEffect],
});

export const showTrafficLayerState = persistedAtom('app.map.layer.traffic.show', false);

export const fleetHealthEventsGeoJsonState = selector<GeoJSON.Feature[]>({
  key: 'map.fleetHealth.events.geojson',
  get: ({ get }) => {
    const events = get(fleetHealthEventsState)?.items;

    if (!events) return [];

    const filteredEvents = events.filter(
      (event) => isValidLatitude(event.lastEvent.latitude) && isValidLongitude(event.lastEvent.longitude)
    );

    const eventMarkers = filteredEvents.map((event) => ({
      event,
      geometry: {
        type: 'Point',
        coordinates: [event.lastEvent.longitude, event.lastEvent.latitude],
      },
    }));

    return eventMarkers.map(
      (eventMarker) =>
        ({
          type: 'Feature',
          properties: { cluster: false, event: eventMarker.event },
          geometry: eventMarker.geometry,
        } as GeoJSON.Feature)
    );
  },
});

export const fleetHealthEventGeoJsonState = selector<GeoJSON.Feature[]>({
  key: 'map.fleetHealth.event.geojson',
  get: ({ get }) => {
    const events = get(fleetHealthEventPreviousEvents)?.flatMap((prevEvent) => prevEvent.events);

    if (!events) return [];

    const filteredEvents = events.filter(
      (event) => isValidLatitude(event.latitude) && isValidLongitude(event.longitude)
    );

    const eventMarkers = filteredEvents.map((event) => ({
      event,
      geometry: {
        type: 'Point',
        coordinates: [event.longitude, event.latitude],
      },
    }));

    return eventMarkers.map(
      (eventMarker) =>
        ({
          type: 'Feature',
          properties: { cluster: false, event: eventMarker.event },
          geometry: eventMarker.geometry,
        } as GeoJSON.Feature)
    );
  },
});
