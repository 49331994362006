import {
  EllipsedText,
  FlexColumn,
  FlexResponsive,
  FlexRow,
  LabeledData,
  LabeledInput,
  MaterialIcon,
  StyledButton,
  StyledTooltip,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { Heading, HeadingLevel } from 'baseui/heading';
import { StyledLink } from 'baseui/link';
import { LabelMedium, ParagraphSmall, ParagraphXSmall } from 'baseui/typography';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { FEEDBACK, FEEDBACK_PREFIXES, REQUIRED_FIELD_TEXT } from '../../constants/app';
import {
  DATA_WIDTH,
  DATA_XL_WIDTH,
  INSTALLATION_STATES_VALID,
  MIN_HEIGHT_MAP,
  MIN_WIDTH_MAP,
} from '../../constants/installations';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { useFeedback } from '../../hooks/useFeedback';
import { useInstallationCoords } from '../../hooks/useInstallationCoords';
import { useLoading } from '../../hooks/useLoading';
import { useModal } from '../../hooks/useModal';
import { useMoment } from '../../hooks/useMoment';
import { useStyles } from '../../hooks/useStyles';
import { updateInstallation } from '../../services/installationService';
import {
  editingInstallationErrorState,
  editingInstallationState,
  editingInstallationTouchedState,
  editingInstallationValidState,
} from '../../storage/Installations';
import { Installation, InstallationAddressRequest, InstallationAddressRequestTouched } from '../../types/installation';
import Map from '../map/Map';
import { InstallationDetailLogs } from './InstallationDetailLogs';
import { InstallationPhone } from './InstallationPhone';
import ChangeJobModal from './modal/ChangeJobModal';

type Props = {
  installation: Installation;
  refreshData: () => void;
};
export function InstallationDetailData({ installation, refreshData }: Readonly<Props>) {
  const { css, theme, classes } = useStyles();
  const { isSmall } = useBreakpoints();
  const { formatDateFromTs } = useMoment();
  const [form, setForm] = useRecoilState(editingInstallationState);
  const [formTouched, setFormTouched] = useRecoilState(editingInstallationTouchedState);
  const resetFormTouched = useResetRecoilState(editingInstallationTouchedState);
  const [enableEditing, setEnableEditing] = useState(false);
  const { showModal, openModal, closeModal } = useModal();
  const { loading, startLoading, stopLoading } = useLoading();
  const { showPositiveFeedback, showFailFeedback } = useFeedback();
  const formValid = useRecoilValue(editingInstallationValidState);
  const formHasError = useRecoilValue(editingInstallationErrorState);
  const [searchParams, setSearchParams] = useSearchParams();
  const openEdit = (searchParams.get('cita')?.length ?? 0) > 0;
  const labelWidth = '115px';
  const canSubmit = !formHasError;
  const canEditJob = INSTALLATION_STATES_VALID.includes(installation.estatusInstalacion.clave) && !installation.jobType;

  useEffect(() => {
    if (!enableEditing) {
      resetFormTouched();
    }
    setForm(installation);
  }, [enableEditing, installation]);

  useEffect(() => {
    if (openEdit && canEditJob) {
      openModal();
    }
  }, [openEdit, canEditJob]);

  useInstallationCoords(form, setForm);

  const clearAppointmentParam = () => {
    searchParams.delete('cita');
    setSearchParams(searchParams);
  };

  const onInputChange = (value: any, field: keyof InstallationAddressRequest) => {
    setForm((prev) => ({ ...prev!, [field]: value }));
  };

  const onUpdate = async () => {
    if (!form || !installation._id) return;
    startLoading();
    const { error } = await updateInstallation(installation._id, form);
    if (!error) {
      showPositiveFeedback(FEEDBACK.created(FEEDBACK_PREFIXES.installation));
      setEnableEditing(false);
      refreshData();
    } else {
      showFailFeedback(error);
    }
    stopLoading();
  };

  const onTouched = (key: keyof InstallationAddressRequestTouched) => {
    setFormTouched((prev) => ({
      ...prev,
      [key]: true,
    }));
  };

  if (!installation) return null;

  const { poliza } = installation;
  const { vehiculo, conductorHabitual, contratante } = poliza;

  return (
    <FlexColumn
      classNames={css({
        flex: '1',
        height: '100%',
        overflow: 'auto',
        paddingBottom: theme.sizing.scale400,
      })}
      gap={theme.sizing.scale400}
    >
      <FlexResponsive>
        <FlexColumn
          classNames={css({ flex: '66%' })}
          gap={theme.sizing.scale400}
        >
          <HeadingLevel>
            <FlexRow
              classNames={css({ alignItems: 'baseline' })}
              gap={theme.sizing.scale300}
            >
              <Heading
                styleLevel={5}
                margin={0}
              >
                <u>Datos Emisión</u>
              </Heading>
              {installation.lastModified && (
                <ParagraphSmall margin={0}>
                  {`(Última modificación ${formatDateFromTs(installation.lastModified)}${
                    installation.lastModifiedBy ? ' - Por ' + installation.lastModifiedBy : ''
                  })`}
                </ParagraphSmall>
              )}
            </FlexRow>
          </HeadingLevel>
          <LabelMedium className={classes.dataHeaderClass}>Poliza</LabelMedium>
          <FlexRow
            classNames={css({
              flexWrap: 'wrap',
              margin: '0 0 0 16px',
              boxSizing: 'border-box',
            })}
            gap={theme.sizing.scale300}
          >
            <LabeledData
              label={'No. Poliza'}
              content={poliza.numPoliza || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Contrato'}
              content={poliza.contrato || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'ID Externo'}
              content={
                <ParagraphSmall margin={0}>
                  {installation.externalId?.length === 24 ? (
                    <StyledLink
                      href={`https://fsm-instalaciones.gorilamx.com/trabajos/?jobId=${installation.externalId}`}
                      target="_blank"
                    >
                      {installation.externalId}
                    </StyledLink>
                  ) : (
                    <EllipsedText maxLines={0}>{installation.externalId || '-'}</EllipsedText>
                  )}
                </ParagraphSmall>
              }
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Estado'}
              content={installation.estatusInstalacion?.descripcion || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Estado GDL'}
              content={installation.gdlStatus || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Fecha de Emisión'}
              content={poliza.fechaEmision ? formatDateFromTs(poliza.fechaEmision, 'DD/MM/yyyy') : '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Inicio de Vigencia'}
              content={poliza.inicioVigencia ? formatDateFromTs(poliza.inicioVigencia, 'DD/MM/yyyy') : '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Fin de Vigencia'}
              content={poliza.finVigencia ? formatDateFromTs(poliza.finVigencia, 'DD/MM/yyyy') : '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
          </FlexRow>

          <LabelMedium className={classes.dataHeaderClass}>Automovil</LabelMedium>
          <FlexRow
            classNames={css({
              flexWrap: 'wrap',
              margin: '0 0 0 16px',
            })}
            gap={theme.sizing.scale300}
          >
            <LabeledData
              label={'Placas'}
              content={vehiculo.placas || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Armadora'}
              content={vehiculo.armadora?.descripcion || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Carroceria'}
              content={vehiculo.carroceria?.descripcion || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Estado'}
              content={vehiculo.estadoCirculacion?.descripcion || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Número de Motor'}
              content={vehiculo.numMotor || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Modelo'}
              content={vehiculo.modelo.toString() || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Vin'}
              content={vehiculo.vin || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
          </FlexRow>

          <LabelMedium className={classes.dataHeaderClass}>Cliente</LabelMedium>
          <FlexRow
            classNames={css({
              flexWrap: 'wrap',
              margin: '0 0 0 16px',
            })}
            gap={theme.sizing.scale300}
          >
            <LabeledData
              label={'Nombre'}
              content={contratante?.nombre || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Paterno'}
              content={contratante?.apePaterno || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Materno'}
              content={contratante?.apeMaterno || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            {contratante.razonSocial && (
              <LabeledData
                label={'Razón Social'}
                content={
                  <ParagraphSmall margin={0}>
                    <EllipsedText maxLines={0}>{contratante.razonSocial}</EllipsedText>
                  </ParagraphSmall>
                }
                labelWidth={labelWidth}
                minWidth={DATA_XL_WIDTH}
                maxWidth={DATA_XL_WIDTH}
              />
            )}
            <LabeledData
              label={'Teléfonos'}
              content={
                contratante?.telefonos?.length ? (
                  <FlexColumn gap={0}>
                    {contratante.telefonos?.map((tel, i) => {
                      return (
                        <FlexColumn
                          key={i}
                          gap={0}
                        >
                          <InstallationPhone
                            installationId={installation._id}
                            phone={tel}
                            refreshData={refreshData}
                          />
                          {tel.tags && tel.tags.length > 0 && (
                            <ParagraphXSmall
                              margin={0}
                              className={css({
                                fontSize: '10px',
                                lineHeight: '10px',
                              })}
                            >
                              ({tel.tags.toString()})
                            </ParagraphXSmall>
                          )}
                        </FlexColumn>
                      );
                    })}
                  </FlexColumn>
                ) : (
                  '-'
                )
              }
              labelWidth={labelWidth}
              minWidth={DATA_XL_WIDTH}
              maxWidth={DATA_XL_WIDTH}
            />
            <LabeledData
              label={'Dirección'}
              content={
                <ParagraphSmall margin={0}>
                  <EllipsedText maxLines={0}>{contratante.direccion || '-'}</EllipsedText>
                </ParagraphSmall>
              }
              labelWidth={labelWidth}
              minWidth={DATA_XL_WIDTH}
              maxWidth={DATA_XL_WIDTH}
            />
          </FlexRow>

          <LabelMedium className={classes.dataHeaderClass}>Conductor habitual</LabelMedium>
          <FlexRow
            classNames={css({
              flexWrap: 'wrap',
              margin: '0 0 0 16px',
            })}
            gap={theme.sizing.scale300}
          >
            <LabeledData
              label={'Nombre'}
              content={conductorHabitual?.nombre || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Paterno'}
              content={conductorHabitual?.apePaterno || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Materno'}
              content={conductorHabitual?.apeMaterno || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            {conductorHabitual?.razonSocial && (
              <LabeledData
                label={'Razón Social'}
                content={
                  <ParagraphSmall margin={0}>
                    <EllipsedText maxLines={0}>{conductorHabitual?.razonSocial}</EllipsedText>
                  </ParagraphSmall>
                }
                labelWidth={labelWidth}
                minWidth={DATA_XL_WIDTH}
                maxWidth={DATA_XL_WIDTH}
              />
            )}
            <LabeledData
              label={'Teléfonos'}
              content={
                conductorHabitual?.telefonos?.length ? (
                  <FlexColumn gap={0}>
                    {conductorHabitual.telefonos?.map((tel, i) => {
                      return (
                        <FlexColumn
                          key={i}
                          gap={0}
                        >
                          <InstallationPhone
                            installationId={installation._id}
                            phone={tel}
                            refreshData={refreshData}
                          />
                          {tel.tags && tel.tags.length > 0 && (
                            <ParagraphXSmall
                              margin={0}
                              className={css({
                                fontSize: '10px',
                                lineHeight: '10px',
                              })}
                            >
                              ({tel.tags.toString()})
                            </ParagraphXSmall>
                          )}
                        </FlexColumn>
                      );
                    })}
                  </FlexColumn>
                ) : (
                  '-'
                )
              }
              labelWidth={labelWidth}
              minWidth={DATA_XL_WIDTH}
              maxWidth={DATA_XL_WIDTH}
            />
            <LabeledData
              label={'Dirección'}
              content={
                <ParagraphSmall margin={0}>
                  <EllipsedText maxLines={0}>{conductorHabitual?.direccion || '-'}</EllipsedText>
                </ParagraphSmall>
              }
              labelWidth={labelWidth}
              minWidth={DATA_XL_WIDTH}
              maxWidth={DATA_XL_WIDTH}
            />
          </FlexRow>
        </FlexColumn>
        <FlexColumn
          classNames={css({ flex: '34%' })}
          gap={theme.sizing.scale400}
        >
          <HeadingLevel>
            <Heading
              styleLevel={5}
              margin={0}
            >
              <u>Datos Cita Instalación</u>
            </Heading>
          </HeadingLevel>
          <LabelMedium
            className={`${classes.dataHeaderClass} ${css({
              alignItems: 'center',
              justifyContent: 'space-between',
              display: 'flex',
            })}`}
          >
            Cita de Instalación
            {canEditJob && (
              <StyledTooltip
                content={'Editar trabajo'}
                showArrow={false}
              >
                <Button
                  size="mini"
                  shape="square"
                  onClick={openModal}
                  kind="tertiary"
                >
                  <MaterialIcon
                    name={'edit'}
                    size="mini"
                  />
                </Button>
              </StyledTooltip>
            )}
          </LabelMedium>
          <FlexRow
            classNames={css({
              flexWrap: 'wrap',
              margin: '0 0 0 16px',
            })}
            gap={theme.sizing.scale300}
          >
            <LabeledData
              label={'Tipo'}
              content={installation.jobType || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Origen'}
              content={installation.origin || '-'}
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
            <LabeledData
              label={'Fecha Instalado'}
              content={
                installation.fchInstaladoFSM ? formatDateFromTs(installation.fchInstaladoFSM, 'DD/MM/yyyy') : '-'
              }
              labelWidth={labelWidth}
              minWidth={DATA_WIDTH}
              maxWidth={DATA_WIDTH}
            />
          </FlexRow>

          {installation.jobData && (
            <>
              <LabelMedium
                className={`${classes.dataHeaderClass} ${css({
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  display: 'flex',
                })}`}
              >
                Datos del Trabajo
              </LabelMedium>
              <FlexRow
                classNames={css({
                  flexWrap: 'wrap',
                  margin: '0 0 0 16px',
                })}
                gap={theme.sizing.scale300}
              >
                {installation.jobData.installationDate && (
                  <LabeledData
                    label={'Fecha de Instalación'}
                    content={formatDateFromTs(installation.jobData.installationDate)}
                    labelWidth={labelWidth}
                    minWidth={DATA_WIDTH}
                    maxWidth={DATA_WIDTH}
                  />
                )}
                {!installation.jobData.installationDate && (
                  <>
                    <LabeledData
                      label={'Nombre'}
                      content={
                        <ParagraphSmall margin={0}>
                          <EllipsedText maxLines={0}>{installation.jobData.name || '-'}</EllipsedText>
                        </ParagraphSmall>
                      }
                      labelWidth={labelWidth}
                      minWidth={DATA_WIDTH}
                      maxWidth={DATA_WIDTH}
                    />
                    <LabeledData
                      label={'Creado'}
                      content={installation.jobData.created ? formatDateFromTs(installation.jobData.created) : '-'}
                      labelWidth={labelWidth}
                      minWidth={DATA_WIDTH}
                      maxWidth={DATA_WIDTH}
                    />
                    <LabeledData
                      label={'Fecha programada'}
                      content={
                        installation.jobData.scheduleDate
                          ? formatDateFromTs(installation.jobData.scheduleDate, 'DD/MM/yyyy')
                          : '-'
                      }
                      labelWidth={labelWidth}
                      minWidth={DATA_WIDTH}
                      maxWidth={DATA_WIDTH}
                    />
                    <LabeledData
                      label={'Latitud'}
                      content={installation.jobData.latitude?.toString() || '-'}
                      labelWidth={labelWidth}
                      minWidth={DATA_WIDTH}
                      maxWidth={DATA_WIDTH}
                    />
                    <LabeledData
                      label={'Longitud'}
                      content={installation.jobData.longitude?.toString() || '-'}
                      labelWidth={labelWidth}
                      minWidth={DATA_WIDTH}
                      maxWidth={DATA_WIDTH}
                    />
                    <LabeledData
                      label={'Geocode'}
                      content={installation.jobData.geocode || '-'}
                      labelWidth={labelWidth}
                      minWidth={DATA_WIDTH}
                      maxWidth={DATA_WIDTH}
                    />
                  </>
                )}
              </FlexRow>
            </>
          )}
          {!canEditJob && (
            <>
              <LabelMedium
                className={`${classes.dataHeaderClass} ${css({
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  display: 'flex',
                })}`}
              >
                Dirección
                {!enableEditing && (
                  <StyledTooltip
                    content={'Editar información'}
                    showArrow={false}
                  >
                    <Button
                      size="mini"
                      shape="square"
                      onClick={() => setEnableEditing(true)}
                      kind="tertiary"
                    >
                      <MaterialIcon
                        name={'edit'}
                        size="mini"
                      />
                    </Button>
                  </StyledTooltip>
                )}
              </LabelMedium>
              <FlexRow
                classNames={css({
                  margin: '0 0 0 16px',
                })}
              >
                <FlexRow
                  classNames={css({
                    flexWrap: 'wrap',
                    height: 'min-content',
                    width: '100%',
                  })}
                  gap={theme.sizing.scale300}
                >
                  {enableEditing && (
                    <FlexColumn
                      classNames={css({ width: '100%', minWidth: DATA_WIDTH })}
                      gap={theme.sizing.scale300}
                    >
                      <LabeledInput
                        label="Latitud"
                        value={form?.latitude ?? ''}
                        onChange={(value) => onInputChange(value, 'latitude')}
                        onBlur={() => onTouched('latitudeTouched')}
                        error={formTouched.latitudeTouched && !formValid.latitude && REQUIRED_FIELD_TEXT}
                        required
                      />
                      <LabeledInput
                        label="Longitud"
                        value={form?.longitude ?? ''}
                        onChange={(value) => onInputChange(value, 'longitude')}
                        onBlur={() => onTouched('longitudeTouched')}
                        error={formTouched.longitudeTouched && !formValid.longitude && REQUIRED_FIELD_TEXT}
                        required
                      />
                      <LabeledInput
                        label="Georeferencia"
                        value={form?.georeference}
                        onChange={(value) => onInputChange(value, 'georeference')}
                        disabled
                        required
                      />
                    </FlexColumn>
                  )}
                  {!enableEditing && (
                    <>
                      <LabeledData
                        label={'Latitud'}
                        content={installation.latitude?.toString() || '-'}
                        labelWidth={labelWidth}
                        minWidth={DATA_WIDTH}
                        maxWidth={DATA_WIDTH}
                      />
                      <LabeledData
                        label={'Longitud'}
                        content={installation.longitude?.toString() || '-'}
                        labelWidth={labelWidth}
                        minWidth={DATA_WIDTH}
                        maxWidth={DATA_WIDTH}
                      />
                      <LabeledData
                        label={'Georeferencia'}
                        content={
                          <ParagraphSmall margin={0}>
                            <EllipsedText maxLines={0}>{installation.georeference || '-'}</EllipsedText>
                          </ParagraphSmall>
                        }
                        labelWidth={labelWidth}
                        minWidth={DATA_WIDTH}
                        maxWidth={isSmall ? DATA_WIDTH : '560px'}
                      />
                    </>
                  )}
                </FlexRow>
              </FlexRow>
              <FlexRow
                classNames={css({
                  minWidth: MIN_WIDTH_MAP,
                  minHeight: MIN_HEIGHT_MAP,
                  width: '100%',
                  height: '100%',
                })}
              >
                <Map
                  fullWindow={false}
                  onlyView={!enableEditing}
                />
              </FlexRow>
              {enableEditing && (
                <FlexRow
                  gap={theme.sizing.scale300}
                  classNames={classes.centeredEnd}
                >
                  <StyledButton
                    kind="tertiary"
                    onClick={() => setEnableEditing(false)}
                  >
                    Cancelar
                  </StyledButton>
                  <StyledButton
                    onClick={onUpdate}
                    disabled={!canSubmit}
                    isLoading={loading}
                  >
                    Guardar
                  </StyledButton>
                </FlexRow>
              )}
            </>
          )}
        </FlexColumn>
      </FlexResponsive>
      <FlexColumn gap={theme.sizing.scale400}>
        <HeadingLevel>
          <Heading
            styleLevel={5}
            margin={0}
          >
            <u>Logs</u>
          </Heading>
        </HeadingLevel>
        <LabelMedium className={classes.dataHeaderClass}>Logs</LabelMedium>
        <InstallationDetailLogs installation={installation} />
      </FlexColumn>

      <ChangeJobModal
        isOpen={showModal}
        onClose={(update) => {
          closeModal();
          if (update) refreshData();
          clearAppointmentParam();
        }}
        installation={installation}
      />
    </FlexColumn>
  );
}
