import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { fleetHealthEventGeoJsonState } from '../../../storage/map';
import FleetHealthLastEventMarker from './FleetHealthLastEventMarker';

function FleetHealthLastEventMarkers() {
  const geojson = useRecoilValue(fleetHealthEventGeoJsonState);

  return (
    <>
      {geojson.map((feature, index) => {
        const { properties } = feature;
        const event = properties?.event;
        if (event) {
          return (
            <FleetHealthLastEventMarker
              key={index}
              event={event}
            />
          );
        }
        return null;
      })}
    </>
  );
}

export default memo(FleetHealthLastEventMarkers);
