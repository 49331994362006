import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { fleetHealthEventStatusListState } from '../storage/fleetHealthStates';
import { FleetHealtEventType, FleethHealthEventAlarmConfig } from '../types/fleetHealth';

export function useFleetHealthEvent() {
  const eventStatusList = useRecoilValue(fleetHealthEventStatusListState);

  const getEventStatusName = useCallback(
    (status: string) => {
      if (status === 'in_progress') return 'En progreso';
      const currentStatus = eventStatusList?.find((s) => status === s.slug);
      if (currentStatus) {
        return currentStatus.value;
      }
      return '-';
    },
    [eventStatusList]
  );

  const getAlarmConfigData = useCallback(
    (eventType: FleetHealtEventType, alarmConfig: FleethHealthEventAlarmConfig) => {
      if (eventType === FleetHealtEventType.maxCommunication) {
        return [
          {
            label: 'Tiempo max. sin comunicar:',
            content: alarmConfig.maxNoCommunication ? alarmConfig.maxNoCommunication.toString() + ' min' : '-',
          },
          {
            label: 'Delta:',
            content: alarmConfig.deltaMinutes ? alarmConfig.deltaMinutes.toString() + ' min' : '-',
          },
        ];
      }
      if (eventType === FleetHealtEventType.alarms) {
        return [
          {
            label: 'Nombre:',
            content: alarmConfig.name ?? '-',
          },
          {
            label: 'Código:',
            content: alarmConfig.code?.toString() ?? '-',
          },
          {
            label: 'Total de eventos:',
            content: alarmConfig.totalEvents?.toString() ?? '-',
          },
          {
            label: 'Máximo de eventos:',
            content: alarmConfig.maxEvents?.toString() ?? '-',
          },
          {
            label: 'Rango de tiempo:',
            content: alarmConfig.timeRange ? alarmConfig.timeRange.toString() + ' min' : '-',
          },
        ];
      }
      if (eventType === FleetHealtEventType.batteryLevel) {
        return [
          {
            label: 'Batería mínima:',
            content: alarmConfig.minBattery ? alarmConfig.minBattery.toString() + '%' : '-',
          },
          {
            label: 'Batería actual:',
            content: alarmConfig.currentBattery ? alarmConfig.currentBattery.toString() + '%' : '-',
          },
        ];
      }
      return [];
    },
    []
  );

  return {
    getEventStatusName,
    getAlarmConfigData,
  };
}
