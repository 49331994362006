import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { getFleetHealthPreviousEvents } from '../services/fleetHealthService';
import { fleetHealthEventPreviousEvents } from '../storage/fleetHealthStates';
import { useLoading } from './useLoading';

export function useFleetHealthTimeline() {
  const { eventId } = useParams();
  const [events, setEvents] = useRecoilState(fleetHealthEventPreviousEvents);
  const [update, setUpdate] = useState(0);
  const { startLoading, stopLoading, loading } = useLoading();

  useEffect(() => {
    if (!eventId) return;

    const loadEvent = async () => {
      startLoading();
      const { previousEvents, error } = await getFleetHealthPreviousEvents(eventId);
      if (previousEvents && !error) {
        setEvents(previousEvents);
      } else {
        setEvents(undefined);
      }
      stopLoading();
    };

    loadEvent();
  }, [eventId, update]);

  const reload = () => {
    setUpdate((prev) => prev + 1);
  };

  return {
    timelineEvents: events,
    loadingEvent: loading,
    reloadEvent: reload,
  };
}
