import { Block } from 'baseui/block';
import { memo } from 'react';
import { useStyles } from '../../../hooks/useStyles';
import { FleetHealthEvent } from '../../../types/fleetHealth';
import FleetHealthEventData from './FleetHealthEventData';

type Props = {
  event: FleetHealthEvent;
};

function TheftCardBody({ event }: Readonly<Props>) {
  const { css, theme } = useStyles();

  return (
    <Block
      className={css({
        padding: theme.sizing.scale400,
      })}
    >
      <FleetHealthEventData
        event={event}
        size="small"
      />
    </Block>
  );
}

export default memo(TheftCardBody);
