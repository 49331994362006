import { Block } from 'baseui/block';
import { Card } from 'baseui/card';
import { StyledDivider } from 'baseui/divider';
import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { fleetHealtEventState } from '../../../storage/fleetHealthEventsStates';
import FleetHealthEventCardHeader from './FleetHealthEventCardHeader';
import FleetHealthEventCardBody from './FleetHealthEventCardBody';

type Props = {
  id: string;
};
function FleetHealthEvntCard({ id }: Readonly<Props>) {
  const event = useRecoilValue(fleetHealtEventState(id));

  if (!event) {
    return <></>; // TODO: skeleton
  }

  return (
    <Block>
      <Card
        overrides={{
          Root: {
            style: {
              height: 'calc(100% - 16px)',
              boxSizing: 'border-box',
              borderLeftWidth: '1px',
              borderRightWidth: '1px',
              borderTopWidth: '1px',
              borderBottomWidth: '1px',
            },
          },
          Contents: {
            style: {
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
            },
          },
          Body: {
            style: {
              marginBottom: 0,
            },
          },
        }}
      >
        <FleetHealthEventCardHeader event={event} />
        <StyledDivider
          $size="cell"
          style={{ marginTop: 0, marginBottom: 0 }}
        />
        <FleetHealthEventCardBody event={event} />
      </Card>
    </Block>
  );
}

export default memo(FleetHealthEvntCard);
