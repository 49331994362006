import { useStyles } from '../../hooks/useStyles';

type Props = {
  height?: number;
  width?: number;
  classNames?: string;
};

export function VerticalDivider({ height, width, classNames }: Readonly<Props>) {
  const { theme, css, concatenateClasses } = useStyles();
  const baseStyles = css({
    width: `${width ?? 1}px`,
    height: height ? `${height}px` : theme.sizing.scale700,
    background: theme.colors.borderOpaque,
  });
  const classes = concatenateClasses(baseStyles, classNames ?? '');

  return <div className={classes} />;
}
