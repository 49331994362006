import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { fleetHealthEventsGeoJsonState } from '../../../storage/map';
import FleetHealthEventMarker from './FleetHealthEventMarker';

function FleetHealthEventMarkers() {
  const geojson = useRecoilValue(fleetHealthEventsGeoJsonState);

  return (
    <>
      {geojson.map((feature, index) => {
        const { properties } = feature;
        const event = properties?.event;
        if (event) {
          return (
            <FleetHealthEventMarker
              key={index}
              event={event}
            />
          );
        }
        return null;
      })}
    </>
  );
}

export default memo(FleetHealthEventMarkers);
