import Pusher from 'pusher-js';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { environment } from '../../environments/environment';
import { updateFleetHealtEventsState } from '../../storage/fleetHealthEventsStates';

const pusher = new Pusher(environment.pusherKey ?? '', { cluster: environment.pusherCluster });

function PusherProvider() {
  const setUpdateHealthEvents = useSetRecoilState(updateFleetHealtEventsState);

  useEffect(() => {
    const channel = pusher.subscribe('health');

    channel.bind('newEvent', (_: any) => {
      setUpdateHealthEvents('update');
    });

    return () => {
      pusher.unbind_all();
      pusher.disconnect();
    };
  }, []);

  return null;
}

export default PusherProvider;
