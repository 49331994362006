import { EnhancedLabeledSelect, FlexColumn } from '@gorila-shared-ui/components';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../../../constants/app';
import { FLEET_HEALTH_EVENT_STATUS_DEFAULTS } from '../../../../constants/fleetHealth';
import { useCampaigns } from '../../../../hooks/useCampaigns';
import { useFeedback } from '../../../../hooks/useFeedback';
import { useStyles } from '../../../../hooks/useStyles';
import useUpdateEffect from '../../../../hooks/useUpdateEffect';
import { updateFleetHealthEventStatus } from '../../../../services/fleetHealthService';
import { createInstallation } from '../../../../services/installationService';
import { fleetHealthEventStatusListState } from '../../../../storage/fleetHealthStates';
import { FleetHealthEvent, FleetHealthEventStatusRequest } from '../../../../types/fleetHealth';
import { InstallationRequest } from '../../../../types/installation';
import { JobTypeCategory } from '../../../../types/job';
import CreateInstallationModal from '../../../installations/modal/CreateInstallationModal';
import { ConfirmModal } from '../../../shared/ConfirmModal';
import { CooldownTimePicker } from '../../../shared/CooldownTimePicker';

type Props = {
  event: FleetHealthEvent;
  isOpen: boolean;
  closeFn: () => void;
  onConfirmFn: (emissionId?: string) => void;
};

export function FleetHealthEventStatusModal({ event, isOpen, closeFn, onConfirmFn }: Readonly<Props>) {
  const { theme } = useStyles();
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const [eventStatusForm, setEventStatusForm] = useState<FleetHealthEventStatusRequest>(
    FLEET_HEALTH_EVENT_STATUS_DEFAULTS
  );
  const isEmission = eventStatusForm.status === 'emission';
  const statusList = useRecoilValue(fleetHealthEventStatusListState);
  const [emissionForm, setEmissionForm] = useState<InstallationRequest | undefined>();
  const { getCampaignByName } = useCampaigns();
  const { asset } = event;

  useUpdateEffect(() => {
    setEventStatusForm(FLEET_HEALTH_EVENT_STATUS_DEFAULTS);
    setEmissionForm(undefined);
  }, [isOpen]);

  useUpdateEffect(() => {
    if (!isEmission) {
      setEmissionForm(undefined);
    } else {
      onInputChange(null, 'cooldownMinutes');
    }
  }, [isEmission]);

  const onConfirmChangeEventStatus = async () => {
    let emissionId: string | undefined = undefined;
    if (isEmission && emissionForm) {
      const { id } = await createInstallation(emissionForm);
      emissionId = id;
      if (emissionId) {
        showPositiveFeedback(FEEDBACK.created(FEEDBACK_PREFIXES.emission));
      } else {
        showFailFeedback(FEEDBACK.failedCreation(FEEDBACK_PREFIXES.emission));
        setEventStatusForm(FLEET_HEALTH_EVENT_STATUS_DEFAULTS);
        closeFn();
        return;
      }
    }
    const { error } = await updateFleetHealthEventStatus(event._id, { ...eventStatusForm, emissionId });
    if (!error) {
      showPositiveFeedback('Se atendió el evento con éxito.');
      onConfirmFn(emissionId);
    } else {
      showFailFeedback('No se pudo atender el evento, inténtalo más tarde.');
    }
    setEventStatusForm(FLEET_HEALTH_EVENT_STATUS_DEFAULTS);
    closeFn();
  };

  const onInputChange = (value: any, field: keyof FleetHealthEventStatusRequest) => {
    setEventStatusForm((prev) => ({ ...prev, [field]: value }));
  };

  const hasCooldownMinutes = eventStatusForm?.cooldownMinutes ? eventStatusForm.cooldownMinutes > 0 : false;
  const cooldownIsValid = isEmission ? !eventStatusForm.cooldownMinutes : hasCooldownMinutes;
  const canSubmit = !!eventStatusForm.status && cooldownIsValid;

  const statusOptions = useMemo(() => {
    return statusList?.map((status) => ({
      id: status.slug,
      label: status.value,
    }));
  }, [statusList]);

  const value = eventStatusForm.status ? [{ id: eventStatusForm.status }] : [];

  const onCloseEmission = () => {
    onInputChange('', 'status');
  };

  return (
    <>
      <ConfirmModal
        isOpen={isOpen}
        onCancel={closeFn}
        onConfirm={onConfirmChangeEventStatus}
        title="Atender evento"
        confirmText="Atender"
        canConfirm={canSubmit}
        description={
          <FlexColumn gap={theme.sizing.scale600}>
            <EnhancedLabeledSelect
              label="Estado:"
              placeholder="Seleccione el estado"
              options={statusOptions}
              value={value}
              onChange={(params) => {
                onInputChange(params.option?.id, 'status');
              }}
              searchable
              required
            />
            {!isEmission && (
              <CooldownTimePicker
                cooldownTime={eventStatusForm.cooldownMinutes ?? undefined}
                onChange={(cooldownTime) => {
                  onInputChange(cooldownTime, 'cooldownMinutes');
                }}
                required
              />
            )}
          </FlexColumn>
        }
      />
      {isEmission && (
        <CreateInstallationModal
          isOpen={!emissionForm}
          onClose={onCloseEmission}
          catchSubmission={(onSubmit) => {
            setEmissionForm(onSubmit);
          }}
          defaultValues={{
            engineNumber: '',
            policyNumber: '',
            vin: asset.vehicle?.vin ?? '',
            licensePlate: asset.vehicle?.licensePlate ?? '',
            client: {
              rfc: asset.subClient.rfc,
              name: asset.subClient.contact?.fullName ?? asset.subClient.name ?? asset.client.name,
              lastName: '',
              motherName: '',
              phone: asset.subClient.contact?.phone ?? '',
              email: asset.subClient.contact?.email ?? '',
            },
            georeference: event.lastEvent.geoReference,
            category: JobTypeCategory.revision,
            latitude: event.lastEvent.latitude,
            longitude: event.lastEvent.longitude,
            brandId: asset.vehicle?.brand?._id,
            subBrandId: asset.vehicle?.subBrand?._id,
            model: asset.vehicle?.year,
            insuranceCompany:
              asset.client.name.toLowerCase() === 'gnp' ? getCampaignByName('gnp') : getCampaignByName('gorila'),
          }}
          defaultBrand={asset.vehicle?.brand}
        />
      )}
    </>
  );
}
