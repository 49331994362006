import { EllipsedText, FlexRow, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { LabelSmall, ParagraphXSmall } from 'baseui/typography';
import { memo } from 'react';
import { generatePath } from 'react-router-dom';
import { FLEET_HEALTH_EVENT_DETAIL_PAGE } from '../../../constants/app';
import { FLEET_HEALTH_EVENT_TYPE } from '../../../constants/fleetHealth';
import { useCamera } from '../../../hooks/useCamera';
import { useModal } from '../../../hooks/useModal';
import { useMoment } from '../../../hooks/useMoment';
import { useStyles } from '../../../hooks/useStyles';
import { FleetHealthEvent } from '../../../types/fleetHealth';
import { isValidLatitude, isValidLongitude } from '../../../utils/map';
import FleetHealthEventAssetModal from './modal/FleetHealthEventAssetModal';
import { Tag } from "baseui/tag";

type Props = {
  event: FleetHealthEvent;
};

function FleetHealthEventCardHeader({ event }: Readonly<Props>) {
  const { theme, css } = useStyles();
  const { zoomTo } = useCamera();
  const { formatDateFromTs } = useMoment();
  const { closeModal, openModal, showModal } = useModal();
  const asset = event.asset;

  const lat = event.lastEvent.latitude;
  const lng = event.lastEvent.longitude;

  const hasValidPosition = isValidLatitude(lat) && isValidLongitude(lng);

  return (
    <FlexRow
      classNames={css({
        padding: `${theme.sizing.scale400} ${theme.sizing.scale400} ${theme.sizing.scale200}`,
        alignItems: 'center',
      })}
    >
      <FlexRow
        gap={theme.sizing.scale200}
        classNames={css({ flexGrow: 1, alignItems: 'center' })}
      >
        <StyledTooltip content="Evento">
          <LabelSmall margin={'2px 0 0'}>
            <EllipsedText
              breakAll
              inline
            >
              {FLEET_HEALTH_EVENT_TYPE[event.eventType] ?? 'N/A'}
            </EllipsedText>
          </LabelSmall>
        </StyledTooltip>
        <StyledTooltip content="Fecha de evento">
          <ParagraphXSmall
            margin={0}
            color={theme.colors.contentSecondary}
          >
            <EllipsedText
              breakAll
              inline
            >
              {`- ${formatDateFromTs(event.created)}`}
            </EllipsedText>
          </ParagraphXSmall>
        </StyledTooltip>
        <Tag 
          closeable={false} 
          kind={asset.isSecondary ? 'warning' : 'positive' }
          overrides={{
            Root: {
              style: () => ({
                marginTop: 0,
                marginBottom: 0,
              })
            },
            Text: {
              style: () => ({
                fontSize: '12px',
                lineHeight: '14px'
              })
            }
          }}
        >
          {asset.isSecondary ? 'Es secundario' : 'Es primario' }
        </Tag>
      </FlexRow>
      <FlexRow gap={0}>
        <StyledTooltip
          content="Información del Activo"
          showArrow={false}
          placement="bottom"
          ignoreBoundary
        >
          <Button
            shape="circle"
            kind="tertiary"
            size="mini"
            onClick={openModal}
          >
            <MaterialIcon
              name="info"
              size="16px"
            />
          </Button>
        </StyledTooltip>
        <StyledTooltip
          content="Abrir seguimiento"
          showArrow={false}
          placement="bottom"
          ignoreBoundary
        >
          <Button
            shape="circle"
            kind="tertiary"
            size="mini"
            onClick={() =>
              window.open(
                generatePath(FLEET_HEALTH_EVENT_DETAIL_PAGE.route, { eventId: event._id }),
                '_blank',
                'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no, height=900px, width=1400px'
              )
            }
          >
            <MaterialIcon
              name="open_in_new"
              size="16px"
            />
          </Button>
        </StyledTooltip>
        {hasValidPosition && (
          <StyledTooltip
            content="Encontrar en el mapa"
            showArrow={false}
            placement="bottom"
            ignoreBoundary
          >
            <Button
              shape="circle"
              kind="tertiary"
              size="mini"
              onClick={() => zoomTo({ lat, lng })}
            >
              <MaterialIcon
                name="location_searching"
                size="16px"
              />
            </Button>
          </StyledTooltip>
        )}
      </FlexRow>
      <FleetHealthEventAssetModal
        event={event}
        onClose={closeModal}
        isOpen={showModal}
      />
    </FlexRow>
  );
}

export default memo(FleetHealthEventCardHeader);
