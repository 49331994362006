import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getFleetHealthEventLogs } from '../../services/fleetHealthService';
import {
  fleetHealthEventLogsPageState,
  fleetHealthEventLogsState,
  loadingFleetHealthEventLogsState,
  searchFleetHealthEventLogState,
  updatefleetHealthEventLogsState,
} from '../../storage/fleetHealthStates';
import { FleetHealthEventLog } from '../../types/fleetHealth';
import { PaginatedProvider } from './PaginatedProvider';

export function FleetHealthEventLogsProvider() {
  const updateLogs = useRecoilValue(updatefleetHealthEventLogsState);
  const setPaginatedLogs = useSetRecoilState(fleetHealthEventLogsState);
  const setLoadingLogs = useSetRecoilState(loadingFleetHealthEventLogsState);
  const { eventId } = useParams();

  if (!eventId) return null;

  return (
    <PaginatedProvider<FleetHealthEventLog>
      searchState={searchFleetHealthEventLogState}
      pageState={fleetHealthEventLogsPageState}
      getPaginated={(page) => getFleetHealthEventLogs(eventId, page)}
      setPaginated={setPaginatedLogs}
      onSearchChange={() => {
        // TODO: Do something if needed when search changes.
      }}
      onUpdateFinish={() => {
        // TODO: Do something if needed when update finish.
      }}
      setLoading={setLoadingLogs}
      update={updateLogs}
    />
  );
}
