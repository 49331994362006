import { atom, selector } from "recoil";
import { MassiveCommandRequest, MassiveCommandRequestTouched } from "../types/massiveCommands";
import { EMPTY_MASSIVE_COMMAND, MASSIVE_TOUCHED_DEFAULTS } from "../constants/massiveCommands";

export const editMassiveState = atom<MassiveCommandRequest>({
  key: 'massive.edit',
  default: EMPTY_MASSIVE_COMMAND,
});

export const editMassiveTouchedState = atom<MassiveCommandRequestTouched>({
  key: 'massive.edit.touched',
  default: MASSIVE_TOUCHED_DEFAULTS,
});

export const editMassiveValidState = selector<{ [key in keyof MassiveCommandRequest]: boolean }>({
  key: 'massive.edit.valid',
  get: ({ get }) => {
    const fields = get(editMassiveState);

    // SET ALL FIELDS TO BE VALID
    const validFields = Object.fromEntries(Object.keys(fields).map((key) => [key, true])) as {
      [key in keyof MassiveCommandRequest]: boolean;
    };

    // VALIDATE REQUIRED FIELDS
    if (!fields.deviceModelId) {
      validFields.deviceModelId = false;
    }
    if (!fields.command) {
      validFields.command = false;
    }
    if (!fields.idents || fields.idents.length === 0) {
      validFields.idents = false;
    }

    return validFields;
  },
});

export const editMassiveErrorState = selector({
  key: 'massive.edit.error',
  get: ({ get }) => {
    const validFields = get(editMassiveValidState);
    return Object.values(validFields).some((valid) => !valid);
  },
});