import { EllipsedText, MaterialIcon } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { ListItem, ListItemLabel } from 'baseui/list';
import { memo } from 'react';
import { IdName } from '../../types/app';

type Props = {
  data: {
    deviceModels: IdName[];
    type: 'selectable' | 'deselectable';
    onClick: (deviceModelId: string) => void;
    isLoaded: (index: number) => boolean;
  };
  index: number;
  style: React.CSSProperties;
};

function DeviceModelItem({ data, index, style }: Props) {
  const deviceModel = data.deviceModels[index];

  return (
    <span style={style}>
      <ListItem
        endEnhancer={() => (
          <Button
            size="mini"
            shape="round"
            kind="tertiary"
            onClick={() => data.onClick(deviceModel._id)}
          >
            <MaterialIcon
              name={data.type === 'selectable' ? 'add' : 'remove'}
              size="mini"
            />
          </Button>
        )}
      >
        <ListItemLabel>
          <EllipsedText inline>{deviceModel?.name || '-'}</EllipsedText>
        </ListItemLabel>
      </ListItem>
    </span>
  );
}

export default memo(DeviceModelItem);
