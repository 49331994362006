import { ApiEndpoint } from "../types/api";
import { ApiError } from "../types/apiResponseError";
import { getRequest, postRequest } from "./api";
import { URLS } from "./urls/urls";

export const getBlPhones = async (
  search?: string
): Promise<{
  phones?: string[];
  error?: ApiError;
}> => {
  const response = await getRequest(URLS.phonesBl.all, {
    queryParams: {
      search,
    },
  }, ApiEndpoint.emissions);
  return {
    phones: response?.data?.phones,
    error: response?.error,
  };
};

export const addRemovePhoneToBl = async (
  phone: string,
  add: boolean
): Promise<{ error?: ApiError }> => {
  const response = await postRequest(URLS.phonesBl.addRemove, {
    body: {
      phone,
      action: add ? "add" : "remove",
    },
  }, ApiEndpoint.emissions);
  return {
    error: response?.error,
  };
};
