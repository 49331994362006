import { EmptyState, FullContainer, MaterialIcon, StyledSearchBar } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { LabelMedium } from 'baseui/typography';
import { useEffect, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { DEFAULT_LIST_ITEM_HEIGHT } from '../../constants/app';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';
import { useLoading } from '../../hooks/useLoading';
import { useStyles } from '../../hooks/useStyles';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getDeviceModelsSearch } from '../../services/deviceModelsService';
import { ApiError } from '../../types/apiResponseError';
import { IdName } from '../../types/app';
import { FlexColumn } from '../ui/FlexColumn';
import { FlexRow } from '../ui/FlexRow';
import DeviceModelItem from './DeviceModeltem';

type Props = {
  assignedDeviceModelIds: string[];
  onAssignedChange: (deviceModelIds: string[]) => void;
  required?: boolean;
  onError?: (error?: ApiError) => void;
};
export function DeviceModelListPicker({
  assignedDeviceModelIds,
  onAssignedChange,
  required,
  onError,
}: Readonly<Props>) {
  const { theme, css, classes } = useStyles();
  const [selectedDeviceModels, setSelectedDeviceModels] = useState<IdName[]>();
  const [selectedDeviceModelHasNext, setSelectedDeviceModelHasNext] = useState(false);
  const [selectedDeviceModelPage, setSelectedDeviceModelPage] = useState(1);
  const {
    startLoading: startSelectedDeviceModelLoading,
    stopLoading: stopSelectedDeviceModelLoading,
    loading: selectedDeviceModelLoading,
  } = useLoading();
  const [unselectedDeviceModels, setUnselectedDeviceModels] = useState<IdName[]>();
  const [unselectedDeviceModelHasNext, setUnselectedDeviceModelHasNext] = useState(false);
  const [unselectedDeviceModelPage, setUnselectedDeviceModelPage] = useState(1);
  const {
    startLoading: startUnselectedDeviceModelLoading,
    stopLoading: stopUnselectedDeviceModelLoading,
    loading: unselectedDeviceModelLoading,
  } = useLoading();
  const { search, debouncedSearch: searchDeviceModel, setSearch: setSearchDeviceModel } = useDebouncedSearch('');

  const isDeviceModelsSelectLoaded = (index: number) =>
    !selectedDeviceModelHasNext || index < (selectedDeviceModels?.length || 0);
  const deviceModelSelectedCount = selectedDeviceModelHasNext
    ? (selectedDeviceModels?.length ?? 0) + 1
    : selectedDeviceModels?.length ?? 0;
  const loadMoreSelectedDeviceModel =
    selectedDeviceModelLoading || !selectedDeviceModelHasNext
      ? () => {}
      : () => {
          setSelectedDeviceModelPage(selectedDeviceModelPage + 1);
        };

  const isDeviceModelsUnselectLoaded = (index: number) =>
    !unselectedDeviceModelHasNext || index < (unselectedDeviceModels?.length || 0);
  const deviceModelUnselectedCount = unselectedDeviceModelHasNext
    ? (unselectedDeviceModels?.length ?? 0) + 1
    : unselectedDeviceModels?.length ?? 0;
  const loadMoreUnselectDeviceModels =
    unselectedDeviceModelLoading || !unselectedDeviceModelHasNext
      ? () => {}
      : () => {
          setUnselectedDeviceModelPage(unselectedDeviceModelPage + 1);
        };

  useUpdateEffect(() => {
    setSelectedDeviceModelPage(0);
    setUnselectedDeviceModelPage(0);
  }, [assignedDeviceModelIds, searchDeviceModel]);

  useEffect(() => {
    if (!assignedDeviceModelIds.length) {
      setSelectedDeviceModels([]);
      setSelectedDeviceModelPage(1);
      return;
    }
    if (selectedDeviceModelPage === 0) {
      setSelectedDeviceModelPage(1);
      return;
    }
    const loadSelectedDeviceModels = async () => {
      startSelectedDeviceModelLoading();
      const { deviceModelsPaginated, error } = await getDeviceModelsSearch(
        selectedDeviceModelPage,
        searchDeviceModel,
        assignedDeviceModelIds
      );
      if (deviceModelsPaginated && !error) {
        setSelectedDeviceModels(deviceModelsPaginated.items);
        setSelectedDeviceModelHasNext(deviceModelsPaginated.hasNext);
      } else {
        onError && onError(error);
        setSelectedDeviceModels([]);
        setSelectedDeviceModelHasNext(false);
      }
      stopSelectedDeviceModelLoading();
    };

    loadSelectedDeviceModels();
  }, [selectedDeviceModelPage]);

  useEffect(() => {
    if (unselectedDeviceModelPage === 0) {
      setUnselectedDeviceModelPage(1);
      return;
    }
    const loadUnselectedDeviceModels = async () => {
      startUnselectedDeviceModelLoading();
      const { deviceModelsPaginated, error } = await getDeviceModelsSearch(
        unselectedDeviceModelPage,
        searchDeviceModel,
        undefined,
        assignedDeviceModelIds
      );
      if (deviceModelsPaginated && !error) {
        setUnselectedDeviceModels(deviceModelsPaginated.items);
        setUnselectedDeviceModelHasNext(deviceModelsPaginated.hasNext);
      } else {
        onError && onError(error);
        setSelectedDeviceModels([]);
        setSelectedDeviceModelHasNext(false);
      }
      stopUnselectedDeviceModelLoading();
    };

    loadUnselectedDeviceModels();
  }, [unselectedDeviceModelPage]);

  if (!selectedDeviceModels && !unselectedDeviceModels) return null;

  const onSelectDeviceModel = (deviceModelId: string) => {
    const deviceModelsSelection = [...assignedDeviceModelIds, deviceModelId];
    onAssignedChange(deviceModelsSelection);
  };

  const onDeselectDeviceModel = (deviceModelId: string) => {
    const deviceModelsSelection = assignedDeviceModelIds?.filter((id) => deviceModelId !== id);
    onAssignedChange(deviceModelsSelection || []);
  };

  const onSelectAllDeviceModels = () => {
    onAssignedChange([
      ...(assignedDeviceModelIds || []),
      ...(unselectedDeviceModels?.map((deviceModel) => deviceModel._id) || []),
    ]);
    setSearchDeviceModel('');
  };

  const onDeselectAllDeviceModels = () => {
    if (searchDeviceModel) {
      const deviceModelsSelection = assignedDeviceModelIds?.filter(
        (id) => !selectedDeviceModels?.some((deviceModel) => deviceModel._id === id)
      );
      onAssignedChange(deviceModelsSelection || []);
    } else {
      onAssignedChange([]);
    }
    setSearchDeviceModel('');
  };

  return (
    <FullContainer
      flexible
      classNames={`${classes.flexFill} 
        ${classes.verticalFlex} 
        ${css({
          gap: theme.sizing.scale400,
          minHeight: '400px',
        })}`}
    >
      <StyledSearchBar
        placeholder="Buscar modelo"
        name="deviceModels-search"
        value={search}
        onChange={(event) => setSearchDeviceModel(event)}
        clearable
      />
      <FlexRow classNames={`${classes.flexFill}`}>
        <FlexColumn classNames={`${classes.flexFill} ${css({ gap: theme.sizing.scale300 })}`}>
          <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'space-between' })}`}>
            <LabelMedium>Disponibles</LabelMedium>
            <Button
              size="mini"
              shape="round"
              kind="tertiary"
              disabled={!unselectedDeviceModels?.length}
              onClick={onSelectAllDeviceModels}
            >
              <MaterialIcon
                name="playlist_add"
                size="mini"
              />
            </Button>
          </FlexRow>
          {unselectedDeviceModels && (
            <>
              {!!unselectedDeviceModels?.length && (
                <ul
                  className={css({
                    margin: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    height: '100%',
                  })}
                >
                  <AutoSizer>
                    {({ width, height }) => (
                      <InfiniteLoader
                        isItemLoaded={isDeviceModelsUnselectLoaded}
                        itemCount={deviceModelUnselectedCount}
                        loadMoreItems={loadMoreUnselectDeviceModels}
                      >
                        {({ onItemsRendered, ref }) => (
                          <List
                            height={height ? height : 500}
                            itemCount={deviceModelUnselectedCount}
                            itemSize={() => DEFAULT_LIST_ITEM_HEIGHT}
                            width={width ? width : 200}
                            itemData={{
                              deviceModels: unselectedDeviceModels,
                              type: 'selectable',
                              onClick: onSelectDeviceModel,
                              isLoaded: isDeviceModelsUnselectLoaded,
                            }}
                            onItemsRendered={onItemsRendered}
                            overscanCount={4}
                            ref={ref}
                          >
                            {DeviceModelItem}
                          </List>
                        )}
                      </InfiniteLoader>
                    )}
                  </AutoSizer>
                </ul>
              )}
              {searchDeviceModel && !unselectedDeviceModels.length && (
                <EmptyState
                  title="No se encontraron resultados"
                  description="Intenta con otra busqueda"
                  centered={false}
                />
              )}
              {!searchDeviceModel && !unselectedDeviceModels.length && (
                <EmptyState
                  title="No tienes ningun modelo por asignar"
                  centered={false}
                />
              )}
            </>
          )}
        </FlexColumn>
        <FlexColumn classNames={`${classes.flexFill}`}>
          <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'space-between' })}`}>
            <FlexRow gap={theme.sizing.scale300}>
              <LabelMedium>Asignados {required && '*'}</LabelMedium>
              <LabelMedium color={theme.colors.contentSecondary}>({assignedDeviceModelIds.length})</LabelMedium>
            </FlexRow>
            <Button
              size="mini"
              shape="round"
              kind="tertiary"
              disabled={!selectedDeviceModels?.length}
              onClick={onDeselectAllDeviceModels}
            >
              <MaterialIcon
                name="playlist_remove"
                size="mini"
              />
            </Button>
          </FlexRow>
          {selectedDeviceModels && (
            <>
              {!!selectedDeviceModels?.length && (
                <ul
                  className={css({
                    margin: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    height: '100%',
                  })}
                >
                  <AutoSizer>
                    {({ width, height }) => (
                      <InfiniteLoader
                        isItemLoaded={isDeviceModelsSelectLoaded}
                        itemCount={100}
                        loadMoreItems={loadMoreSelectedDeviceModel}
                      >
                        {({ onItemsRendered, ref }) => (
                          <List
                            height={height ? height : 500}
                            itemCount={deviceModelSelectedCount}
                            itemSize={() => DEFAULT_LIST_ITEM_HEIGHT}
                            width={width ? width : 200}
                            itemData={{
                              deviceModels: selectedDeviceModels,
                              type: 'deselectable',
                              onClick: onDeselectDeviceModel,
                              isLoaded: isDeviceModelsSelectLoaded,
                            }}
                            onItemsRendered={onItemsRendered}
                            overscanCount={4}
                            ref={ref}
                          >
                            {DeviceModelItem}
                          </List>
                        )}
                      </InfiniteLoader>
                    )}
                  </AutoSizer>
                </ul>
              )}
              {searchDeviceModel && !selectedDeviceModels.length && (
                <EmptyState
                  title="No se encontraron resultados"
                  description="Intenta con otra busqueda"
                  centered={false}
                />
              )}
              {!searchDeviceModel && !selectedDeviceModels.length && (
                <EmptyState
                  title="No tienes ningun modelo asignado"
                  centered={false}
                />
              )}
            </>
          )}
        </FlexColumn>
      </FlexRow>
    </FullContainer>
  );
}
