import { ContentContainer } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { MassiveCommsFiltersBar } from '../../massiveCommands/MassiveCommsFiltersBar';
import { MassiveCommsList } from '../../massiveCommands/MassiveCommsList';

export function CommsMassivePage() {
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <MassiveCommsFiltersBar onReloadPage={() => setReloadPage(!reloadPage)} />
      <MassiveCommsList reloadPage={reloadPage} />
    </ContentContainer>
  );
}
