import { ColoredCircle, EllipsedText, FlexColumn } from '@gorila-shared-ui/components';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { HeadingXSmall, ParagraphSmall } from 'baseui/typography';
import { memo, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { ASSET_TYPE, ASSET_TYPE_CONTAINER, ASSET_TYPE_VEHICLE } from '../../../../constants/asset';
import { FLEET_HEALTH_EVENT_TYPE } from '../../../../constants/fleetHealth';
import { useMoment } from '../../../../hooks/useMoment';
import { useStyles } from '../../../../hooks/useStyles';
import { AssetType } from '../../../../types/asset';
import { FleetHealthEvent } from '../../../../types/fleetHealth';
import SectionedData from '../../../shared/SectionedData';
import { FlexRow } from '../../../ui/FlexRow';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  event: FleetHealthEvent;
};
function FleetHealthEventAssetModal({ onClose, isOpen, event }: Props) {
  const { theme, css, classes } = useStyles();
  const { formatDateFromTs } = useMoment();
  const { asset } = event;
  const labelWidth = '120px';

  const assetData = useMemo(() => {
    return [
      {
        label: 'Ident:',
        content: asset.device.ident || '-',
      },
      {
        label: 'Etiqueta:',
        content: asset.label || '-',
      },
      {
        label: 'Cliente:',
        content: asset.client.name || '-',
      },
      {
        label: 'Subcliente:',
        content: asset.subClient.name || '-',
      },
      {
        label: 'Tipo de activo:',
        content: ASSET_TYPE[asset.assetType as AssetType] || '-',
      },
    ];
  }, [asset]);

  const vehicleData = useMemo(
    () =>
      asset.assetType === ASSET_TYPE_VEHICLE && asset.vehicle
        ? [
            {
              label: 'Color:',
              content: asset.vehicle.color ? <ColoredCircle color={asset.vehicle.color} /> : '-',
            },
            {
              label: 'Año:',
              content: asset.vehicle.year ? asset.vehicle.year.toString() : '-',
            },
            {
              label: 'Marca:',
              content: asset.vehicle.brand?.name || '-',
            },
            {
              label: 'Submarca:',
              content: asset.vehicle.subBrand?.name || '-',
            },
            {
              label: 'Placa:',
              content: asset.vehicle.licensePlate || '-',
            },
            {
              label: 'Tipo de vehículo:',
              content: asset.vehicle.vehicleType || '-',
            },
            {
              label: 'Vin:',
              content: asset.vehicle.vin || '-',
            },
          ]
        : [],
    [asset.assetType, asset.vehicle]
  );
  const containerData = useMemo(
    () =>
      asset.assetType === ASSET_TYPE_CONTAINER && asset.container
        ? [
            {
              label: 'Identificador:',
              content: asset.container.identifier || '-',
            },
            {
              label: 'Tipo de contenedor:',
              content: asset.container.containerType || '-',
            },
          ]
        : [],
    [asset.assetType, asset.container]
  );

  if (!asset) return null;

  return createPortal(
    <Modal
      animate
      closeable
      onClose={onClose}
      isOpen={isOpen}
      size="auto"
      overrides={{
        Root: {
          style: {
            zIndex: '100',
          },
        },
      }}
    >
      <ModalHeader>
        <FlexRow
          gap={theme.sizing.scale200}
          classNames={classes.centeredStart}
        >
          <HeadingXSmall margin={0}>
            <EllipsedText
              breakAll
              inline
            >
              {FLEET_HEALTH_EVENT_TYPE[event.eventType] ?? 'N/A'}
            </EllipsedText>
          </HeadingXSmall>
          <ParagraphSmall
            margin={0}
            color={theme.colors.contentSecondary}
          >
            <EllipsedText
              breakAll
              inline
            >
              {`- ${formatDateFromTs(event.created)}`}
            </EllipsedText>
          </ParagraphSmall>
        </FlexRow>
      </ModalHeader>
      <ModalBody>
        <FlexColumn
          gap={theme.sizing.scale800}
          classNames={css({ minWidth: '350px' })}
        >
          <SectionedData
            title="Datos del activo"
            data={assetData}
            labelWidth={labelWidth}
          />
          {asset.assetType === ASSET_TYPE_VEHICLE && (
            <SectionedData
              title="Datos del vehiculo"
              data={vehicleData}
              labelWidth={labelWidth}
            />
          )}
          {asset.assetType === ASSET_TYPE_CONTAINER && (
            <SectionedData
              title="Datos del contenedor"
              data={containerData}
              labelWidth={labelWidth}
            />
          )}
        </FlexColumn>
      </ModalBody>
    </Modal>,
    document.body
  );
}

export default memo(FleetHealthEventAssetModal);
