import { memo } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../hooks/useStyles';
import { FleetHealthRule } from '../../../types/fleetHealth';
import { StyledModal } from '../../shared/StyledModal';
import { FlexColumn } from '../../ui/FlexColumn';
import { FleetHealthRuleForm } from '../FleetHealthRuleForm';

type Props = {
  isOpen: boolean;
  onClose: (update?: boolean) => void;
  fleetHealthRule?: FleetHealthRule;
};

function FleetHealthRuleModal({ onClose, isOpen, fleetHealthRule }: Props) {
  const { css } = useStyles();

  return createPortal(
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      closeable
      title={fleetHealthRule ? `Regla: ${fleetHealthRule?.name}` : 'Nueva regla'}
      size="auto"
      content={
        <FlexColumn
          classNames={css({
            width: '60vw',
            maxWidth: '750px',
            minWidth: '450px',
            boxSizing: 'border-box',
            padding: '1rem',
            borderTop: '1px solid rgb(226, 226, 226)',
            maxHeight: '75vh',
            overflow: 'hidden',
          })}
        >
          <FleetHealthRuleForm
            afterSave={() => onClose(true)}
            onCancel={onClose}
            fleetHealthRule={fleetHealthRule}
          />
        </FlexColumn>
      }
    />,
    document.body
  );
}

export default memo(FleetHealthRuleModal);
