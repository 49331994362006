import { EnhancedLabeledSelect } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useMemo } from 'react';
import { COOLDOWN_TIMES } from '../../constants/fleetHealth';

type Props = {
  onChange: (cooldownTime: number) => void;
  cooldownTime?: number;
  maxWidth?: number;
  disabled?: boolean;
  required?: boolean;
};

export function CooldownTimePicker({
  onChange,
  cooldownTime,
  maxWidth,
  disabled = false,
  required = false,
}: Readonly<Props>) {
  const cooldownTimeOptions = useMemo(() => {
    const options = [
      ...Object.entries(COOLDOWN_TIMES).map(([key, cooldownTime]) => ({
        id: key,
        label: cooldownTime,
      })),
    ];
    return options;
  }, []);

  return (
    <EnhancedLabeledSelect
      label="Tiempo de espera:"
      placeholder="Seleccione el tiempo de espera"
      options={cooldownTimeOptions}
      onChange={(params: OnChangeParams) => {
        onChange(+(params.option?.id ?? 0));
      }}
      value={cooldownTime ? [{ id: cooldownTime }] : []}
      searchable
      maxWidth={maxWidth}
      disabled={disabled}
      fullWidth
      required={required}
    />
  );
}
