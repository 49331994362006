import { FlexColumn, FlexResponsive, LabeledInput } from '@gorila-shared-ui/components';
import { LabelMedium } from 'baseui/typography';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { REQUIRED_FIELD_TEXT, VELID_FIELD_TEXT } from '../../constants/app';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { useStyles } from '../../hooks/useStyles';
import {
  editInstallationState,
  editInstallationTouchedState,
  editInstallationValidState,
} from '../../storage/Installations';
import { Brand } from '../../types/brand';
import { InstallationRequest, InstallationRequestTouched } from '../../types/installation';
import { BrandSelect } from '../shared/selects/BrandSelect';
import { CampaignSelect } from '../shared/selects/CampaignSelect';
import JobTypeCategorySelect from '../shared/selects/JobTypeCategorySelect';
import { SubBrandSelect } from '../shared/selects/SubBrandSelect';
import { InstallationClientForm } from './InstallationClientForm';

type Props = {
  defaultValues?: InstallationRequest;
  defaultBrand?: Brand;
};

export function InstallationForm({ defaultValues, defaultBrand }: Readonly<Props>) {
  const { classes, css } = useStyles();
  const [form, setForm] = useRecoilState(editInstallationState);
  const [formTouched, setFormTouched] = useRecoilState(editInstallationTouchedState);
  const formValid = useRecoilValue(editInstallationValidState);
  const { isLarge } = useBreakpoints();

  useEffect(() => {
    if (defaultValues) {
      setForm(defaultValues);
    }
  }, [defaultValues]);

  const onTouched = (key: keyof InstallationRequestTouched) => {
    setFormTouched((prev) => ({
      ...prev,
      [key]: true,
    }));
  };

  const onChangeInput = (value: any, field: keyof InstallationRequest) => {
    setForm((prev) => {
      return {
        ...prev!,
        [field]: value,
      };
    });
  };

  return (
    <FlexColumn>
      <FlexResponsive>
        <FlexColumn
          classNames={css({
            flex: '1',
          })}
        >
          <JobTypeCategorySelect
            label="Categoría"
            selectedId={form?.category}
            onChangeId={(value) => onChangeInput(value, 'category')}
            onBlur={() => onTouched('categoryTouched')}
            error={formTouched.categoryTouched && !formValid.category && REQUIRED_FIELD_TEXT}
            disabled={!!defaultValues}
            required
          />
          <BrandSelect
            label="Marca"
            selectedBrandId={form?.brandId}
            onChangeBrandId={(brandId) => onChangeInput(brandId, 'brandId')}
            onBlur={() => onTouched('brandTouched')}
            error={formTouched.brandTouched && !formValid.brandId && REQUIRED_FIELD_TEXT}
            brand={defaultBrand}
            required
          />
          <SubBrandSelect
            label="Submarca"
            selectedBrandId={form?.brandId}
            selectedSubBrandId={form?.subBrandId}
            onChangeSubBrand={(subBrandId) => onChangeInput(subBrandId, 'subBrandId')}
            onBlur={() => onTouched('subBrandTouched')}
            error={formTouched.subBrandTouched && !formValid.subBrandId && REQUIRED_FIELD_TEXT}
            disabled={!form?.brandId}
            required
          />
          <LabeledInput
            label="Modelo"
            placeholder="Ingresa el modelo"
            value={form.model}
            type="number"
            onChange={(value) => {
              onChangeInput(value, 'model');
            }}
            onBlur={() => onTouched('modelTouched')}
            error={
              formTouched.modelTouched && !formValid.model && (!form.model ? REQUIRED_FIELD_TEXT : VELID_FIELD_TEXT())
            }
            required
          />
          <LabeledInput
            label="Número de motor"
            placeholder="Ingresa el número del motor"
            value={form.engineNumber}
            onChange={(value) => {
              onChangeInput(value, 'engineNumber');
            }}
            onBlur={() => onTouched('engineNumberTouched')}
            error={formTouched.engineNumberTouched && !formValid.engineNumber && REQUIRED_FIELD_TEXT}
            required
          />
          <LabeledInput
            label="Poliza"
            placeholder="Ingresa el número de poliza"
            value={form.policyNumber}
            onChange={(value) => {
              onChangeInput(value, 'policyNumber');
            }}
          />
          <LabeledInput
            label="Vin"
            placeholder="Ingresa el número de serie"
            value={form.vin}
            onChange={(value) => {
              onChangeInput(value, 'vin');
            }}
            onBlur={() => onTouched('vinTouched')}
            error={formTouched.vinTouched && !formValid.vin && REQUIRED_FIELD_TEXT}
            required
          />
          <LabeledInput
            label="Placa"
            placeholder="Ingresa el número de placas"
            value={form.licensePlate}
            onChange={(value) => {
              onChangeInput(value, 'licensePlate');
            }}
            onBlur={() => onTouched('licensePlateTouched')}
            error={formTouched.licensePlateTouched && !formValid.licensePlate && REQUIRED_FIELD_TEXT}
            required
          />
          <CampaignSelect
            selectedCampaignId={form.insuranceCompany}
            onChange={(value) => {
              onChangeInput(value, 'insuranceCompany');
            }}
            onBlur={() => onTouched('insuranceCompanyTouched')}
            error={formTouched.insuranceCompanyTouched && !formValid.insuranceCompany && REQUIRED_FIELD_TEXT}
            required
          />
        </FlexColumn>
        <FlexColumn
          classNames={css({
            flex: '1',
            justifyContent: 'space-between',
            flexDirection: !isLarge ? 'column' : 'column-reverse',
          })}
        >
          <FlexColumn>
            <LabelMedium
              className={classes.dataHeaderClass}
              margin={isLarge ? '16px 0px 0px' : '0px'}
            >
              Datos del cliente
            </LabelMedium>
            <InstallationClientForm />
          </FlexColumn>
        </FlexColumn>
      </FlexResponsive>
    </FlexColumn>
  );
}
