import { atom, selector, selectorFamily } from 'recoil';
import { PaginatedResponse } from '../types/api';
import { FleetHealthEvent } from '../types/fleetHealth';

export const loadingFleetHealthEventsState = atom({
  key: 'app.client.fleetHealtEvents.loading',
  default: false,
});

export const fleetHealthEventsState = atom<PaginatedResponse<FleetHealthEvent> | undefined>({
  key: 'app.client.fleetHealtEvents',
  default: undefined,
});

export const fleetHealtEventIdsState = selector<string[] | undefined>({
  key: 'app.client.fleetHealtEventIds',
  get: ({ get }) => {
    const fleetHealtEvents = get(fleetHealthEventsState);
    if (!fleetHealtEvents) return;
    return fleetHealtEvents.items.map((fleetHealtEvent) => fleetHealtEvent._id);
  },
});

export const fleetHealtEventState = selectorFamily<FleetHealthEvent | undefined, string>({
  key: 'app.client.fleetHealtEvent',
  get:
    (id) =>
    ({ get }) => {
      const fleetHealtEvents = get(fleetHealthEventsState);
      return fleetHealtEvents?.items.find((fleetHealtEvent) => fleetHealtEvent._id === id);
    },
});

export const searchFleetHealtEventState = atom<string | undefined>({
  key: 'app.client.fleetHealtEvents.search',
  default: undefined,
});

export const fleetHealtEventsPageState = atom({
  key: 'app.client.fleetHealtEvents.page',
  default: 1,
});

export const updateFleetHealtEventsState = atom<string | undefined>({
  key: 'app.client.fleetHealtEvents.update',
  default: undefined,
});

export const updatedFleetHealtEventIdState = atom<string | undefined>({
  key: 'app.fleetHealtEvents.key.updated',
  default: undefined,
});
