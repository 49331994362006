import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getFleetHealthEvents } from '../../services/fleetHealthService';
import {
  fleetHealtEventsPageState,
  fleetHealthEventsState,
  loadingFleetHealthEventsState,
  searchFleetHealtEventState,
  updateFleetHealtEventsState,
} from '../../storage/fleetHealthEventsStates';
import { FleetHealthEvent } from '../../types/fleetHealth';
import { PaginatedProvider } from './PaginatedProvider';

export function FleetHealthEventsProvider() {
  const updateEvents = useRecoilValue(updateFleetHealtEventsState);
  const setPaginatedEvents = useSetRecoilState(fleetHealthEventsState);
  const setLoadingEvents = useSetRecoilState(loadingFleetHealthEventsState);

  return (
    <PaginatedProvider<FleetHealthEvent>
      searchState={searchFleetHealtEventState}
      pageState={fleetHealtEventsPageState}
      getPaginated={getFleetHealthEvents}
      setPaginated={setPaginatedEvents}
      onSearchChange={() => {
        // TODO: Do something if needed when search changes.
      }}
      onUpdateFinish={() => {
        // TODO: Do something if needed when update finish.
      }}
      setLoading={setLoadingEvents}
      update={updateEvents}
    />
  );
}
