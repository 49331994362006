import { ApiEndpoint } from '../types/api';
import { ApiError } from '../types/apiResponseError';
import { JobSchedule, Worker } from '../types/job';
import { getRequest, postRequest } from './api';
import { URLS } from './urls/urls';

export const getWorkersList = async (
  installationId: string,
  appointmentDate: string,
  jobTypeId: string
): Promise<{
  workers?: Worker[];
  error?: ApiError;
}> => {
  const response = await getRequest(URLS.jobs.workers(installationId), {
    queryParams: {
      appointmentDate: new Date(appointmentDate).getTime(),
      jobTypeId,
    },
  }, ApiEndpoint.emissions);
  return {
    workers: response?.data,
    error: response?.error,
  };
};

export const createFSMJob = async (
  installationId: string,
  jobSchedule: JobSchedule,
  assetId: string
): Promise<{ id?: string; error?: ApiError }> => {
  const response = await postRequest(URLS.jobs.create(installationId), {
    body: {
      ...jobSchedule,
      appointmentDate: +new Date(jobSchedule.appointmentDate),
      name: jobSchedule.name ?? '',
      description: jobSchedule.description ?? '',
      assetId,
    },
  }, ApiEndpoint.emissions);
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};
