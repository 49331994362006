import { FleetHealthEvent } from '../types/fleetHealth';
import { isValidLatitude, isValidLongitude } from './map';

export const getValidPositions = (events: FleetHealthEvent[]): number[][] => {
  const positions = events.map((event) => {
    const lat = event.lastEvent.latitude;
    const lng = event.lastEvent.longitude;

    const hasValidPosition = !!lat && !!lng && isValidLatitude(lat) && isValidLongitude(lng);

    return hasValidPosition ? [lng, lat] : undefined;
  });
  return positions.filter((position): position is number[] => position !== undefined);
};
