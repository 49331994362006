import { EmptyState, FlexColumn, Loading, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { LabelLarge, LabelSmall, ParagraphSmall } from 'baseui/typography';
import { memo } from 'react';
import { useCamera } from '../../../hooks/useCamera';
import { useFleetHealthTimeline } from '../../../hooks/useFleetHealthEventTimeline';
import { useModal } from '../../../hooks/useModal';
import { useMoment } from '../../../hooks/useMoment';
import { useStyles } from '../../../hooks/useStyles';
import { FleetHealthLastEvent, FleetHealthPreviousEvent } from '../../../types/fleetHealth';
import { isValidLatitude, isValidLongitude } from '../../../utils/map';
import { FlexRow } from '../../ui/FlexRow';
import FleetHealthLastEventModal from './modal/FleetHealthLastEventModal';

function FleetHealthEventTimelineEvents() {
  const { theme, css } = useStyles();
  const { timelineEvents, loadingEvent, reloadEvent } = useFleetHealthTimeline();

  return (
    <FlexColumn classNames={css({ overflow: 'hidden' })}>
      {!timelineEvents && !loadingEvent && <EmptyState title="No se pudo cargar el timeline." />}
      {!timelineEvents && loadingEvent && <Loading />}
      {timelineEvents && (
        <>
          <FlexRow classNames={css({ alignItems: 'center', justifyContent: 'space-between' })}>
            <LabelLarge>{`${timelineEvents.length} ${timelineEvents.length === 1 ? 'Evento' : 'Eventos'}`}</LabelLarge>
            <StyledTooltip
              content="Recargar timeline"
              showArrow={false}
              placement="top"
              ignoreBoundary
            >
              <Button
                size="compact"
                shape="circle"
                onClick={reloadEvent}
                kind="tertiary"
                isLoading={loadingEvent}
              >
                <MaterialIcon
                  name={'sync'}
                  size="mini"
                />
              </Button>
            </StyledTooltip>
          </FlexRow>
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              gap: '20px',
              height: '100%',
              '::-webkit-scrollbar': {
                width: '8px',
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: theme.colors.backgroundOverlayDark,
                borderRadius: '4px',
              },
              '::-webkit-scrollbar-track': {
                background: theme.colors.backgroundSecondary,
                borderRadius: '4px',
              },
            })}
          >
            {timelineEvents.map((event, i) => {
              return (
                <div
                  key={i}
                  style={{ position: 'relative' }}
                >
                  <EventPoint
                    event={event}
                    index={i}
                  />
                  {i !== timelineEvents.length - 1 && (
                    <div
                      className={css({
                        width: '2px',
                        borderLeft: '2px dotted',
                        borderLeftColor: theme.colors.backgroundOverlayLight,
                        position: 'absolute',
                        top: '25px',
                        left: '25px',
                        height: 'calc(100% + 15px)',
                      })}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </FlexColumn>
  );
}

const TimelinePoint = memo(({ event }: { event: FleetHealthPreviousEvent }) => {
  const { theme, css, classes } = useStyles();
  const { formatDateFromTs } = useMoment();

  return (
    <FlexRow
      gap={0}
      classNames={classes.centeredStart}
    >
      <div
        className={css({
          ...theme.typography.HeadingXSmall,
          color: theme.colors.contentTertiary,
          width: '30px',
          height: '30px',
          backgroundColor: theme.colors.backgroundTertiary,
          borderRadius: '50%',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          transform: 'translateX(-15px)',
        })}
      >
        <MaterialIcon
          name={'label'}
          size="15px"
        />
      </div>
      <LabelSmall style={{ flex: 1 }}>
        {event.configuration.name ?? 'N/A'}
        <span style={{ color: theme.colors.contentSecondary }}> - {formatDateFromTs(event.created)}</span>
      </LabelSmall>
    </FlexRow>
  );
});

const EventPoint = memo(({ event, index }: { event: FleetHealthPreviousEvent; index: number }) => {
  const { css, theme } = useStyles();
  const { formatDateFromTs } = useMoment();

  return (
    <div
      className={css({
        width: '100%',
      })}
    >
      <div
        key={index}
        className={css({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          position: 'relative',
          paddingTop: '10px',
          paddingRight: '10px',
          paddingLeft: '25px',
          paddingBottom: '10px',
          borderRadius: '5px',
        })}
      >
        <TimelinePoint event={event} />
        {event.events?.length > 0 && (
          <ul
            className={css({
              width: '-webkit-fill-available',
              paddingLeft: theme.sizing.scale1000,
              margin: 0,
              listStyle: 'none',
              position: 'relative',
            })}
          >
            {event.events?.map((e, i) => (
              <li key={`secondary-event-${i}`}>
                <LastEventRow event={e} />
              </li>
            ))}
            <li>
              <ParagraphSmall
                margin={0}
                style={{ textAlign: 'center', marginLeft: '-50px' }}
              >
                {event.attendedUntil ? 'Atendido: ' + formatDateFromTs(event.attendedUntil) : 'Sin atender'}.
              </ParagraphSmall>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
});

const LastEventRow = memo(({ event }: { event: FleetHealthLastEvent }) => {
  const { classes, theme, css } = useStyles();
  const { zoomTo } = useCamera();
  const { closeModal, openModal, showModal } = useModal();

  return (
    <FlexRow
      classNames={classes.centeredBetween}
      gap={0}
    >
      <div
        className={css({
          transform: 'translateX(-50%)',
          backgroundColor: theme.colors.negative,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '22px',
          height: '22px',
          paddingBottom: '2px',
          boxSizing: 'border-box',
        })}
      >
        <MaterialIcon
          name="warning"
          size="14px"
          color={theme.colors.contentInversePrimary}
        />
      </div>
      <ParagraphSmall
        margin={0}
        style={{ flex: 1 }}
      >
        {event.geoReference ?? 'N/A'}
      </ParagraphSmall>
      <StyledTooltip
        content="Información"
        showArrow={false}
        placement="top"
        ignoreBoundary
      >
        <Button
          size="mini"
          shape="square"
          onClick={openModal}
          kind="tertiary"
        >
          <MaterialIcon
            name="info"
            size="14px"
          />
        </Button>
      </StyledTooltip>
      {isValidLatitude(event.latitude) && isValidLongitude(event.longitude) && (
        <StyledTooltip
          content="Encontrar en el mapa"
          showArrow={false}
          placement="top"
          ignoreBoundary
        >
          <Button
            size="mini"
            shape="square"
            onClick={() => {
              zoomTo({
                lat: event.latitude,
                lng: event.longitude,
              });
            }}
            kind="tertiary"
          >
            <MaterialIcon
              name="location_searching"
              size="14px"
            />
          </Button>
        </StyledTooltip>
      )}
      <FleetHealthLastEventModal
        event={event}
        isOpen={showModal}
        onClose={closeModal}
      />
    </FlexRow>
  );
});

export default FleetHealthEventTimelineEvents;
