import { LabeledData, MaterialIcon } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { StatefulPopover } from 'baseui/popover';
import { LabelMedium } from 'baseui/typography';
import { memo, useMemo } from 'react';
import { Marker } from 'react-map-gl';
import { generatePath } from 'react-router-dom';
import { FLEET_HEALTH_EVENT_DETAIL_PAGE } from '../../../constants/app';
import { FLEET_HEALTH_EVENT_TYPE } from '../../../constants/fleetHealth';
import { useCamera } from '../../../hooks/useCamera';
import { useFleetHealthEvent } from '../../../hooks/useFleetHealthEvent';
import { useMoment } from '../../../hooks/useMoment';
import { useStyles } from '../../../hooks/useStyles';
import { FleetHealthEvent } from '../../../types/fleetHealth';
import { isValidLatitude, isValidLongitude } from '../../../utils/map';
import { FlexColumn } from '../../ui/FlexColumn';
import { FlexRow } from '../../ui/FlexRow';
import { VerticalDivider } from '../../ui/VerticalDivider';

type Props = {
  event: FleetHealthEvent;
};

function FleetHealthEventMarker({ event }: Readonly<Props>) {
  const { lastEvent } = event;
  const { theme, css } = useStyles();
  const { zoomTo } = useCamera();
  const { formatDateFromTs } = useMoment();
  const { getEventStatusName } = useFleetHealthEvent();
  const iconWrapper = css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 0,
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    background: theme.colors.negative,
    paddingBottom: '3px',
    boxSizing: 'border-box',
  });
  const icon = useMemo(
    () => (
      <span className={iconWrapper}>
        <MaterialIcon
          name="warning"
          size="16px"
          color="#FFF"
        />
      </span>
    ),
    [iconWrapper]
  );

  if (!isValidLatitude(lastEvent.latitude) || !isValidLongitude(lastEvent.longitude)) return null;

  const onClick = () => {
    zoomTo({
      lat: lastEvent.latitude,
      lng: lastEvent.longitude,
    });
  };

  return (
    <Marker
      longitude={lastEvent.longitude}
      latitude={lastEvent.latitude}
      onClick={onClick}
    >
      <StatefulPopover
        showArrow
        autoFocus={false}
        returnFocus
        placement="top"
        triggerType="hover"
        popoverMargin={0}
        content={({ close }) => (
          <FlexColumn
            gap={theme.sizing.scale300}
            classNames={css({
              paddingRight: theme.sizing.scale600,
              paddingLeft: theme.sizing.scale600,
              paddingTop: theme.sizing.scale500,
              paddingBottom: theme.sizing.scale500,
            })}
          >
            <FlexRow gap={theme.sizing.scale200}>
              <LabelMedium>{FLEET_HEALTH_EVENT_TYPE[event.eventType]}</LabelMedium>
            </FlexRow>
            <FlexColumn gap={theme.sizing.scale0}>
              <LabeledData
                label={'Fecha:'}
                content={formatDateFromTs(event.created)}
              />
              <LabeledData
                label={'Estado:'}
                content={getEventStatusName(event.status)}
              />
              <LabeledData
                label={'Configuración:'}
                content={event.configuration.name}
              />
            </FlexColumn>
            <FlexRow
              classNames={css({
                alignItems: 'center',
                justifyContent: 'space-evenly',
              })}
            >
              <Button
                shape="circle"
                kind="tertiary"
                size="compact"
                onClick={() =>
                  window.open(
                    generatePath(FLEET_HEALTH_EVENT_DETAIL_PAGE.route, { eventId: event._id }),
                    '_blank',
                    'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no, height=900px, width=1400px'
                  )
                }
              >
                <MaterialIcon
                  name="open_in_new"
                  size="compact"
                />
              </Button>
              <VerticalDivider />
              <Button
                shape="circle"
                kind="tertiary"
                size="compact"
                onClick={() => {
                  close();
                  zoomTo({
                    lat: lastEvent.latitude,
                    lng: lastEvent.longitude,
                  });
                }}
              >
                <MaterialIcon
                  name="zoom_in_map"
                  size="compact"
                />
              </Button>
            </FlexRow>
          </FlexColumn>
        )}
        overrides={{
          Inner: {
            style: {
              backgroundColor: theme.colors.backgroundPrimary,
            },
          },
          Arrow: {
            style: {
              backgroundColor: theme.colors.backgroundPrimary,
            },
          },
        }}
      >
        {icon}
      </StatefulPopover>
    </Marker>
  );
}

export default memo(FleetHealthEventMarker);
