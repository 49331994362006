import { FullContainer } from '@gorila-shared-ui/components';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { FLEET_HEALTH_EVENT_DETAIL_PAGE, FLEET_HEALTH_EVENTS_PAGE } from '../../constants/app';
import { useStyles } from '../../hooks/useStyles';
import { pageState } from '../../storage/app';
import Map from '../map/Map';

function PageLayout() {
  const { css } = useStyles();
  const page = useRecoilValue(pageState);
  const hasMap = page === FLEET_HEALTH_EVENTS_PAGE.id || page === FLEET_HEALTH_EVENT_DETAIL_PAGE.id;

  return (
    <FullContainer
      flexible
      classNames={css({
        position: 'relative',
        overflow: 'hidden',
      })}
    >
      {hasMap && <Map />}
      <Outlet />
    </FullContainer>
  );
}

export default memo(PageLayout);
