import { PaginatedColumn } from '../types/app';
import { MassiveCommandRequest, MassiveCommandRequestTouched } from '../types/massiveCommands';

export const MASSIVE_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Dispositivo',
    key: 'deviceModel',
  },
  {
    label: 'Comando',
    key: 'command',
  },
  {
    label: 'Creado',
    key: 'created',
  },
  {
    label: 'Acciones',
    key: 'actions',
    minWidth: 120,
    maxWidth: 120,
    centered: true,
  },
];

export const EMPTY_MASSIVE_COMMAND: MassiveCommandRequest = {
  deviceModelId: '',
  command: '',
  idents: []
};

export const MASSIVE_TOUCHED_DEFAULTS: MassiveCommandRequestTouched = {
  deviceModelTouched: false,
  commandTouched: false,
  fileTouched: false,
};
