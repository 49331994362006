import { EnhancedLabeledSelect } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { fleetHealthAlarmTypesState } from '../../../storage/fleetHealthStates';
import { FleetHealthAlarmType } from '../../../types/fleetHealth';

type Props = {
  selectedAlarmTypeId?: number;
  onChangeAlarmTypeId: (alarmTypeId?: number, alarmType?: FleetHealthAlarmType) => void;
  isFilter?: boolean;
  label?: string;
  placeholder?: string;
  error?: React.ReactNode;
  onBlur?: () => void;
  inline?: boolean;
  clearable?: boolean;
  required?: boolean;
};
export function FleetHealthAlarmTypeSelect({
  selectedAlarmTypeId,
  onChangeAlarmTypeId,
  isFilter = true,
  label = 'Tipo de alarma',
  placeholder = 'Seleciona un tipo de alarma',
  error,
  onBlur,
  inline,
  clearable = false,
  required = false,
}: Readonly<Props>) {
  const alarmTypeList = useRecoilValue(fleetHealthAlarmTypesState);

  const alarmTypesOptions = useMemo(() => {
    if (!alarmTypeList) return;
    const alarmTypeOptions: {
      id: number | undefined;
      label: string;
    }[] = alarmTypeList.map((alarmType) => ({ id: alarmType.notificationId, label: alarmType.name }));
    if (!required) {
      alarmTypeOptions?.unshift({
        id: undefined,
        label: isFilter ? 'Todas' : 'Ninguna',
      });
    }
    return alarmTypeOptions;
  }, [alarmTypeList, isFilter, required]);

  const onAlarmTypeChange = (params: OnChangeParams) => {
    if (params.option) {
      onChangeAlarmTypeId(
        params.option.id as number,
        alarmTypeList?.find((a) => a.notificationId === params.option?.id)
      );
    } else {
      onChangeAlarmTypeId(undefined);
    }
  };

  return (
    <EnhancedLabeledSelect
      label={label}
      options={alarmTypesOptions}
      value={selectedAlarmTypeId ? [{ id: selectedAlarmTypeId }] : undefined}
      onChange={onAlarmTypeChange}
      fullWidth
      onBlur={() => {
        onBlur && onBlur();
      }}
      searchable
      inline={inline}
      inset={inline}
      error={error}
      required={required}
      clearable={clearable}
      placeholder={placeholder}
    />
  );
}
