import { LabeledIconData } from '@gorila-shared-ui/components';
import { AdminUser } from '../../types/user';
import { GRANTED_PROJECT_ADMINISTRATIVE, GRANTED_PROJECT_CORE, GRANTED_PROJECT_FSM, GRANTED_PROJECT_MONITORING, GRATED_PROJECTS } from '../../constants/users';

type Props = {
  user: AdminUser;
};
export function UserInfo({ user }: Readonly<Props>) {
  const labelWidth = '190px';
  return (
    <>
      {user.name && (
        <LabeledIconData
          label="Nombre:"
          data={user.name}
          icon="person"
          labelWidth={labelWidth}
        />
      )}
      {user.email && (
        <LabeledIconData
          label="Correo electrónico:"
          data={user.email}
          icon="alternate_email"
          labelWidth={labelWidth}
        />
      )}
      {user.phoneNumber && (
        <LabeledIconData
          label="Teléfono :"
          data={user.phoneNumber}
          icon="phone_iphone"
          labelWidth={labelWidth}
        />
      )}
      {user.grantedProjects?.length! > 0 && (
        <LabeledIconData
          label="Proyectos con acceso concedido:"
          data={`${user.grantedProjects?.filter(
            (p): 
              p is typeof GRANTED_PROJECT_MONITORING | 
                typeof GRANTED_PROJECT_ADMINISTRATIVE | 
                typeof GRANTED_PROJECT_FSM => 
                  p !== GRANTED_PROJECT_CORE
            )?.map((p) => GRATED_PROJECTS[p])}`
          }
          icon="business_center"
          labelWidth={labelWidth}
        />
      )}
    </>
  );
}
